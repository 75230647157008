<template>
  <div id="app">
    <div class="container-fluid">
      <div
        @click="$router.push('/radraum/' + workout_id)"
        v-if="!$route.path.includes('/radraum') && history.length && workout_id && workoutStarted"
        style="cursor: pointer"
        class="bottom-button-active-cycling"
      >
        <img src="@/assets/images/bike.png" style="width: 22px" />
      </div>
      <div
        @click="returnToWorkout()"
        v-if="!$route.path.includes('/kraftraum') && activeKraftraumWorkout"
        style="cursor: pointer"
        class="bottom-button-active-kraftraum"
      >
        <i class="fa fa-heartbeat" style="font-size: 22px; color: white;"></i>
      </div>
      <div
        id="nav-bar"
        v-show="!($route.path.includes('/radraum/') && workoutStarted)"
        :class="{ 'nav-bar-fixed': !($route.path.includes('/radraum/') && workoutStarted) }"
      >
        <div class="row">
          <div class="col-xl-12 menu d-none d-xl-block">
            <img
              @click="$router.push({ name: 'Dashboard' })"
              v-if="isLoggedIn && !subIsActive('/login') && !subIsActive('/register')"
              class="pull-left logo pointer"
              alt="PL logo"
              style="box-shadow: none; max-height: 40px"
              v-bind:src="
                $client.getClient() == 'pushinglimits'
                  ? require('@/assets/images/PL-Club-Logo-klein.png')
                  : require('@/assets/images/' + $client.getClient() + '.jpg')
              "
            />

            <span
              v-if="isLoggedIn && !subIsActive('/einfuehrung') && !subIsActive('/login') && !subIsActive('/register')"
            >
              <router-link
                to="/training/dashboard"
                :class="{
                  'router-link-exact-active': subIsActive('/training') || $route.path == '/',
                }"
                >{{ $t("Training") }}</router-link
              >
              <router-link
                @click.native="$scrollToTop()"
                to="/auswertung/workouts"
                :class="{
                  'router-link-exact-active': subIsActive('/auswertung'),
                }"
                >{{ $t("Auswertung") }}</router-link
              >

              <router-link
                @click.native="$scrollToTop()"
                to="/radraum"
                :class="{
                  'router-link-exact-active': subIsActive('/radraum'),
                }"
                >{{ $t("Radraum") }}</router-link
              >
              <router-link
                @click.native="$scrollToTop()"
                to="/kraftraum"
                :class="{
                  'router-link-exact-active': subIsActive('/kraftraum'),
                }"
                >Kraftraum <small>(Neu)</small></router-link
              >

              <router-link
                @click.native="$scrollToTop()"
                to="/esszimmer"
                :class="{
                  'router-link-exact-active': subIsActive('/esszimmer'),
                }"
                >{{ $t("Esszimmer") }}</router-link
              >
              <!--
              <router-link
                @click.native="$scrollToTop()"
                to="/media"
                :class="{
                  'router-link-exact-active': subIsActive('/media'),
                }"
                >Media</router-link
              >-->

              <router-link v-if="isLoggedIn" @click.native="$scrollToTop()" to="/profil">
                <span>{{ $t("Profil") }}</span>
              </router-link>

              <a @click="logout" class="pull-right">Logout</a>
            </span>
            <span v-if="!$store.getters.isLoggedIn || $route.path === '/login' || $route.path === '/register'">
              <router-link to="/register">{{ $t("Start") }}</router-link>
              <router-link to="/login">{{ $t("Login") }}</router-link>
            </span>
          </div>
          <div class="col-6 menu d-block d-xl-none">
            <div
              class="pointer"
              v-if="!subIsActive('/einfuehrung')"
              @click="
                showMenu = !showMenu;
                showSubMenu = showMenu ? false : showSubMenu;
                setScrollLock(false);
              "
            >
              <div>
                <i class="fa fa-bars" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-6 menu d-block d-xl-none centered">
            <img
              v-if="isLoggedIn"
              style="width: 35px; cursor: pointer"
              width="35px"
              @click="
                showMenu = false;
                $scrollToTop();
              "
              alt="PL logo"
              v-bind:src="
                $client.getClient() == 'pushinglimits'
                  ? require('@/assets/images/PL-Club-Logo-klein.png')
                  : require('@/assets/images/' + $client.getClient() + '.jpg')
              "
            />
          </div>

          <div
            class="col-12 dropdown-content d-block d-xl-none"
            v-if="showMenu"
            @click="
              showMenu = !showMenu;
              showSubMenu = showMenu ? false : showSubMenu;
              setScrollLock(false);
            "
            style="text-align: left"
          >
            <span
              v-if="isLoggedIn && !subIsActive('/einfuehrung') && !subIsActive('/login') && !subIsActive('/register')"
            >
              <div style="background: #fff; padding: 0px 10px; border-radius: 10px">
                <router-link
                  to="/training/dashboard"
                  :class="{
                    'router-link-exact-active': subIsActive('/training') || exactSubIsActive('/'),
                  }"
                  >{{ $t("Training") }}</router-link
                >
              </div>
              <a
                href="/#/training/dashboard"
                class="mob-submenu"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/training/dashboard') || $route.path == '/',
                }"
                >{{ $t("Dashboard") }}</a
              >
              <a
                href="/#/training/kalender"
                class="mob-submenu"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/training/kalender'),
                }"
              >
                {{ $t("Kalender") }}</a
              >

              <a
                href="/#/training/liste"
                class="mob-submenu"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/training/liste'),
                }"
              >
                {{ $t("Trainingspläne") }}
              </a>

              <a
                href="/#/training/FragDenCoach"
                class="mob-submenu"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/training/FragDenCoach'),
                }"
              >
                {{ $t("Wissen") }}</a
              >

              <!--
              <a
                href="/#/training/wissen"
                class="mob-submenu"
                :class="{
                  'mobile-router-link-exact-active':
                    subIsActive('/training/wissen'),
                }"
              >
                Wissen
              </a>-->

              <div style="background: #fff; padding: 0px 15px; border-radius: 10px">
                <router-link
                  @click.native="$scrollToTop()"
                  to="/auswertung/workouts"
                  :class="{
                    'router-link-exact-active': subIsActive('/auswertung'),
                  }"
                  >{{ $t("Auswertung") }}</router-link
                >
              </div>
              <a
                class="mob-submenu"
                :href="'/#/auswertung/workouts'"
                :class="{
                  'mobile-router-link-exact-active':
                    subIsActive('/auswertung/workouts') || subIsActive('/auswertung/workout'),
                }"
              >
                {{ $t("Einzelne Einheiten") }}
              </a>
              <a
                class="mob-submenu"
                :href="'/#/auswertung/uebersicht'"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/auswertung/uebersicht'),
                }"
              >
                {{ $t("Übersicht") }}
              </a>

              <a
                class="mob-submenu"
                :href="'/#/auswertung/belastung'"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/auswertung/belastung'),
                }"
              >
                {{ $t("Belastung & Fitness") }}
              </a>

              <a
                class="mob-submenu"
                :href="'/#/auswertung/zonen'"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/auswertung/zonen'),
                }"
              >
                {{ $t("Zeit in Zonen") }}
              </a>

              <a
                class="mob-submenu"
                :href="'/#/auswertung/substratverbrauch'"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/auswertung/substratverbrauch'),
                }"
              >
                {{ $t("Substratverbrauch") }}
              </a>
              <a
                class="mob-submenu"
                :href="'/#/auswertung/vergleiche'"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/auswertung/vergleiche'),
                }"
              >
                Leistungen/HR
                <!-- TODO: Scatter Chart mit  (leistung oder speed) / bpm  auf Y
               und Zeit auf X Achse und Trendlinie zur Leistungsentwicklung! -->
              </a>

              <a
                class="mob-submenu"
                :href="'/#/auswertung/leistungskurven'"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/auswertung/leistungskurven'),
                }"
              >
                {{ $t("Leistungskurven") }}
              </a>

              <div style="background: #fff; padding: 0px 15px; border-radius: 10px">
                <router-link
                  @click.native="$scrollToTop()"
                  to="/esszimmer"
                  :class="{
                    'router-link-exact-active': subIsActive('/esszimmer'),
                  }"
                  >{{ $t("Esszimmer") }}</router-link
                >
              </div>
              <a
                class="mob-submenu"
                :href="'/#/esszimmer'"
                :class="{
                  'mobile-router-link-exact-active': exactSubIsActive('/esszimmer'),
                }"
              >
                {{ $t("Rezepte") }}
              </a>

              <a
                class="mob-submenu"
                :href="'/#/esszimmer/foodlog'"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/esszimmer/foodlog'),
                }"
              >
                {{ $t("Foodlog") }}
              </a>

              <a
                class="mob-submenu"
                href="/#/esszimmer/plan"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/esszimmer/plan'),
                }"
              >
                {{ $t("Vorschläge") }}
              </a>
              <router-link
                @click.native="$scrollToTop()"
                to="/radraum"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/radraum'),
                }"
                >{{ $t("Radraum") }}</router-link
              >
              <router-link
                @click.native="$scrollToTop()"
                to="/kraftraum"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/kraftraum'),
                }"
                >Kraftraum <small style="color: blue">(Neu)</small></router-link
              >

              <router-link
                v-if="isLoggedIn"
                :class="{
                  'mobile-router-link-exact-active': subIsActive('/profil'),
                }"
                @click.native="$scrollToTop()"
                to="/profil"
              >
                <span>{{ $t("Profil") }}</span>
              </router-link>

              <hr />
              <a @click="logout">{{ $t("Logout") }}</a>
            </span>
            <span v-if="!$store.getters.isLoggedIn || $route.path === '/login' || $route.path === '/register'">
              <router-link to="/register">{{ $t("Start") }}</router-link>
              <router-link to="/login">{{ $t("Login") }}</router-link>
            </span>
          </div>
        </div>
      </div>
      <FlashMessage :position="'right top'"></FlashMessage>
      <div
        class="wrap"
        :style="isLoggedIn && !subIsActive('/login') && !subIsActive('/register') ? 'max-width:1600px' : ''"
      >
        <router-view />

        <div class="top-spacer-75" style="text-align: center; color: #777; padding: 20px; border-radius: 10px">
          <hr style="border-top: 1px solid #ddd" />

          <div class="row">
            <div class="col-md-6 top-spacer-50">
              <strong v-if="$client.getClient() != 'pushinglimits'"
                >eine Zusammenarbeit von {{ $client.getClient() }} und dem
              </strong>
              <strong>pushinglimits.club</strong>
              <div>//</div>

              <a style="color: #666" href="/#/Impressum">{{ $t("Impressum") }}</a>
              <div>//</div>

              <a
                style="color: #666"
                href="https://www.iubenda.com/nutzungsbedingungen/44469436"
                target="_blank"
                title="AGBs "
                >Allgemeine Geschäftsbedingungen</a
              >

              <div>//</div>

              <a
                style="color: #666"
                target="_blank"
                href="https://www.iubenda.com/privacy-policy/44469436"
                title="Datenschutzerklärung "
                >Datenschutzerklärung</a
              >
              <div>//</div>

              <a style="color: #666" href="/#/faq" title="Fragen und Antworten ">{{
                $t("Fragen und Antworten (FAQ)")
              }}</a>

              <div>//</div>

              <a style="color: #666" target="_blank" href="https://shop.pushing-limits.de" title="Shop "
                >SHOP <span style="color: blue">(Neu)</span></a
              >

              <div>//</div>

              <a style="color: #666" href="/#/glossar" title="Glossar">{{ $t("Glossar Schwimmen") }}</a>

              <div>//</div>
              Mail:
              <a style="color: #666" href="mailto:feedback@pushinglimits.club">feedback@pushinglimits.club</a>
              <div>//</div>

              <div
                v-if="
                  !(
                    $parent.appstoreState &&
                    $parent.appstoreState.product1 &&
                    $parent.appstoreState.product2 &&
                    $parent.appstoreState.product3 &&
                    ($parent.appstoreState.product1.state == 'owned' ||
                      ($parent.appstoreState.product2.state == 'owned') |
                        ($parent.appstoreState.product3.state == 'owned'))
                  )
                "
              >
                <a style="color: #666" href="/#/cancel">{{ $t("Abo kündigen") }}</a>
              </div>

              <div
                v-if="
                  $parent.appstoreState &&
                  $parent.appstoreState.product1 &&
                  $parent.appstoreState.product2 &&
                  $parent.appstoreState.product3 &&
                  ($parent.appstoreState.product1.state == 'owned' ||
                    $parent.appstoreState.product2.state == 'owned' ||
                    $parent.appstoreState.product3.state == 'owned')
                "
              >
                <a style="color: #666" @click="$parent.manageSubscription()">
                  {{ $t("Abo kündigen") }}
                </a>
              </div>

              <div>//</div>

              <a style="color: #666" href="/#/delete_acccount" title="Account löschen">Account löschen</a>
            </div>
            <div class="col-md-6 top-spacer-75">
              <img
                class="mainlogo"
                alt="PL logo"
                style="box-shadow: none; width: 160px"
                v-bind:src="
                  $client.getClient() == 'pushinglimits'
                    ? require('@/assets/images/PL-Club-Logo.png')
                    : require('@/assets/images/' + $client.getClient() + '.jpg')
                "
              />
              <span v-if="$client.getClient() != 'pushinglimits'" style="font-size: 30px; padding: 20px; margin: auto">
                X</span
              >
              <img
                class="mainlogo"
                alt="PL logo"
                style="box-shadow: none; max-width: 160px"
                v-if="$client.getClient() !== 'pushinglimits'"
                v-bind:src="require('@/assets/images/PL-Club-Logo.png')"
              />

              <div class="row" v-if="platform == 'web'">
                <div class="col-lg-12 top-spacer-50">
                  <a href="https://apps.apple.com/de/app/pushing-limits-club/id1606897234">
                    <img
                      src="@/assets/images/appstore.png"
                      style="margin-top: 5px; height: 45px; display: inline-block"
                    />
                  </a>
                  &nbsp;<a href="https://play.google.com/store/apps/details?id=club.pushinglimits">
                    <img src="@/assets/images/playstore.png" style="margin-top: 5px; display: inline-block" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!--
          <hr class="top-spacer-50" style="border-top: 1px solid #ddd" />

          <div class="top-spacer-50" style="text-align: right; font-size: 10px">
            <div class="row">
              <div class="col-lg-8"></div>
              <div class="col-lg-2"></div>
              <div class="col-lg-2" style="color: #aaa">
                icons<br />
                <a
                  style="color: #aaa"
                  target="_blank"
                  href="https://icons8.com/icon/74691/marathonschwimmen"
                  >Marathonschwimmen</a
                >
                icon by
                <a target="_blank" style="color: #aaa" href="https://icons8.com"
                  >Icons8</a
                ><br />
                <a
                  style="color: #aaa"
                  target="_blank"
                  href="https://icons8.com/icon/47443/radfahren-auf-straße"
                  >Radfahren auf Straße</a
                >
                icon by
                <a target="_blank" style="color: #aaa" href="https://icons8.com"
                  >Icons8</a
                ><br />
                <a
                  style="color: #aaa"
                  target="_blank"
                  href="https://icons8.com/icon/9806/laufen"
                  >Laufen</a
                >
                icon by
                <a target="_blank" style="color: #aaa" href="https://icons8.com"
                  >Icons8</a
                ><br />
                <a
                  style="color: #aaa"
                  target="_blank"
                  href="https://icons8.com/icon/kGX9L2tKAeaN/strength"
                  >Strength</a
                >
                icon by
                <a target="_blank" style="color: #aaa" href="https://icons8.com"
                  >Icons8</a
                ><br />

                <a
                  style="color: #aaa"
                  href="https://www.flaticon.com/de/kostenlose-icons/triathlon"
                  title="triathlon Icons"
                  >Triathlon Icons erstellt von Freepik - Flaticon</a
                >
                <br />
                <a
                  target="_blank"
                  style="color: #aaa"
                  href="https://icons8.com/icon/59735/balkendiagramm"
                  >Balkendiagramm</a
                >
                icon by
                <a target="_blank" style="color: #aaa" href="https://icons8.com"
                  >Icons8</a
                >
              </div>
            </div>
          </div>-->
          <!--
          <div style="color: #888" class="top-spacer-50">
            Die dargestellten Daten sind von uns entwickelte Annäherungen oder
            Trainingsvorschläge. Sie können nicht jeden Athleten individuell
            vollständig richtig abbilden.<br />
            Genauere Werte kannst du gegenfalls durch Leistungsdiagnostiken oder
            Ärzt:innen erhalten. Die angezeigten Werte sind in keiner Weise eine
            medizinische Empfehlung. <br />Halte im Zweifel bzgl. deines
            Trainings immer Rücksprache mit Trainer:innen oder ärztlichen
            Fachkräften. <br /><br />
            <hr />
            <br />
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Capacitor } from "@capacitor/core";
import axios from "axios";
import { App } from "@capacitor/app";
import { Plugins } from "@capacitor/core";
const { AppleWorkoutPlugin } = Plugins;
import AppleHealthService from "@/services/AppleHealthService";
import storageHelper from "@/services/storageHelper";
// import NotificationService from "@/services/NotificationService";

// import AppRate from "cordova-plugin-apprate";
// import { CapacitorHealthkit } from "@perfood/capacitor-healthkit"; //SampleNames,

/*
const READ_PERMISSIONS = [
  "calories",
  "stairs",
  "activity",
  "steps",
  "distance",
  "duration",
  "weight",
  "workout",
];*/

export default {
  name: "AppComponent",

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "PLC",
    // all titles will be injected into this template
    titleTemplate: "%s | Pushing Limits Club",
  },
  data: function () {
    return {
      // ANDERE
      platform: "",
      appstoreState: {},
      showMenu: false,
      showSubMenu: false,
      store: undefined,
      isPrepared: false,
      reloadUser: false,
      loadUntil: 0,
      waitForLogin: undefined,
      originalTransactionAndOrderIds: [],
      status: "",
      thresholds: {},

      selectedFeeling: null,

      // RADRAUM
      autoSaveInterval: null,
      powerMeter: undefined,
      heartMeter: undefined,
      cadenceMeter: undefined,
      bluetoothInitialized: false,
      resistance: 19,
      controlDevice: undefined,
      wasReset: false,

      powerSamples: [],
      hrSamples: [],
      cadenceSamples: [],
      erg_mode: false,
      timer: 0,
      workoutTimes: [],
      workoutStarted: false,
      ridingState: undefined,
      chart_watts_should: [],
      watts_should: 0,
      chart_watts_should_current: [],
      labels_current: [...Array(89).keys()],
      done_current: [],
      intervalWorker: undefined,
      history: [],
      hrdone: [],
      done: [],
      workout: {},
      cadence: 0,
      cadence_should: 0,
      watts: 0,
      workout_id: "",
      heart: 0,
      labels: [],
      timer_mode: "remaining", 

      showFoodInCalendar: false,
      activeKraftraumWorkout: false,
      coreTempMeter: undefined,
      coreTempSamples: [],
      coreTempQuality: null,
      coreTempHeatStrainIndex: null,
      coreTempHeatStrainSamples: []
    };
  },
  computed: {
    isLoggedIn() {
    const tokenExists = this.$store?.getters?.isLoggedIn || false;
    const sessionCookie = document.cookie.includes("session_token"); // Example cookie check
      return tokenExists || sessionCookie;
    },

    user() {
      return this.$store?.getters?.user || {};
    },
  },
  methods: {
    async syncWorkoutsToApple(force = false) {
      if (this.platform == "ios") {
        try {
          await AppleHealthService.syncWorkoutsToApple(force);
        } catch (error) {
          console.error("Error syncing workouts to Apple: " + error, error);
        }
      }
    },

    saveCyclingState() {
      const state = {
        powerMeter: this.powerMeter,
        heartMeter: this.heartMeter,
        cadenceMeter: this.cadenceMeter,
        bluetoothInitialized: this.bluetoothInitialized,
        resistance: this.resistance,
        controlDevice: this.controlDevice,
        powerSamples: this.powerSamples,
        hrSamples: this.hrSamples,
        coreTempSamples: this.coreTempSamples,
        coreTempHeatStrainSamples: this.coreTempHeatStrainSamples,
        cadenceSamples: this.cadenceSamples,
        erg_mode: this.erg_mode,
        timer: this.timer,
        workoutTimes: this.workoutTimes,
        workoutStarted: this.workoutStarted,
        ridingState: this.ridingState,
        chart_watts_should: this.chart_watts_should,
        watts_should: this.watts_should,
        chart_watts_should_current: this.chart_watts_should_current,
        labels_current: this.labels_current,
        done_current: this.done_current,
        history: this.history,
        hrdone: this.hrdone,
        done: this.done,
        workout: this.workout,
        cadence: this.cadence,
        cadence_should: this.cadence_should,
        watts: this.watts,
        workout_id: this.workout_id,
        heart: this.heart,
        labels: this.labels,
        timer_mode: this.timer_mode,
      };
      localStorage.setItem("cyclingState", JSON.stringify(state));
    },

    loadCyclingState() {
      const savedState = localStorage.getItem("cyclingState");
      if (savedState) {
        const stateObject = JSON.parse(savedState);

        // if more than 1 power samples in saved state
        if (stateObject.powerSamples && stateObject.powerSamples.length > 0) {
          for (const key in stateObject) {
            // Check if the key is a direct property of the instance
            if (Object.prototype.hasOwnProperty.call(this, key)) {
              this[key] = stateObject[key];
            }
          }
        }
      }
    },

    removeCyclingState() {
      console.log("cyclingState remove...");
      localStorage.removeItem("cyclingState");
    },

    startAutoSaveCyclingState() {
      const _self = this;

      this.autoSaveInterval = setInterval(() => {
        console.log("Store Cycling State...");
        _self.saveCyclingState();
      }, 15000); // 15 seconds
    },

    endAutoSave() {
      // Clear the interval using the stored interval ID
      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval);
        this.autoSaveInterval = null;
      }
    },

    async logout() {
      const _self = this;

      // Beenden Sie ggf. laufende Worker
      if (_self.intervalWorker) {
        _self.intervalWorker.terminate();
      }
      _self.intervalWorker = undefined;
      _self.history = [];
      _self.workoutStarted = false;
      _self.workout_id = "";
      _self.workoutTimes = [];
      _self.ridingState = [];

      try {
        await this.$store?.dispatch("logout");
        this.$router.push("/login");
      } catch (err) {
        console.error("Fehler beim Logout:", err);
        this.$parent.flashMessage.show({
          status: "error",
          title: "Fehler",
          time: 4500,
          message: "Das Ausloggen ist fehlgeschlagen. Bitte versuche es erneut.",
          icon: false,
        });
      }
    },

    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },

    verifyIfLoggedInOrWait(p) {
      const _self = this;

      if (this.isLoggedIn) {
        p.verify();
      } else {
        setTimeout(function () {
          _self.verifyIfLoggedInOrWait(p);
        }, 1500);
      }
    },

    kmh2mkm(kmh) {
      if (kmh > 0) {
        return this.$moment.utc((60 / kmh) * 60 * 1000).format("mm:ss");
      } else {
        return 0;
      }
    },

    exactSubIsActive(input) {
      return this.$route.path === input; // current path starts with this path string
    },

    manageSubscription() {
      window.store.manageSubscriptions();
    },

    // hh:mm:ss or h:mm:ss or mm:ss
    toSeconds(str) {
      return str
        .split(":")
        .reverse()
        .reduce((prev, curr, i) => prev + curr * Math.pow(60, i + 1), 0);
    },

    async checkAndSaveSubscriptionUser(originalTransactionId, orderId) {
      this.loadUntil = Date.now() + 1000 * 15;

      if (this.isLoggedIn) {
        const _self = this;
        return axios
          .post(this.$host + "/iap/finish", {
            originalTransactionId: originalTransactionId,
            orderId: orderId,
          })
          .then(function (res) {
            if (res.data.ok) {
              return true;
            } else {
              _self.message = res.data.message;
              return false;
            }
          })
          .catch(function (err) {
            console.log("FEHLER1", err);
          });
      } else {
        console.log("Waiting for Login");
        const _self = this;
        setTimeout(async () => {
          console.log("wait for check and save");
          await _self.checkAndSaveSubscriptionUser(originalTransactionId, orderId);
        }, 3500);
      }
    },

    updateStore() {
      window.store.update();
    },
    /*
    appRate() {
      AppRate.setPreferences({
        displayAppName: "Pushing Limits Club",
        usesUntilPrompt: 7,
        promptAgainForEachNewVersion: false,
        reviewType: {
          ios: "AppStoreReview",
          android: "InAppBrowser",
        },
        storeAppURL: {
          ios: "1606897234",
          android: "market://details?id=club.pushinglimits",
        },
        customLocale: {
          title: "Möchtest du uns Feedback geben?",
          message: "Es dauert nur eine Minute und hilft uns sehr!",
          cancelButtonLabel: "Nein",
          laterButtonLabel: "Später",
          rateButtonLabel: "Jetzt",
          yesButtonLabel: "Ja",
          noButtonLabel: "Nein",
          appRatePromptTitle: "Gefällt dir der Pushing Limits Club?",
          feedbackPromptTitle: "Möchtest du uns Feedback geben?",
        },
        callbacks: {
          handleNegativeFeedback: function () {
            window.open("mailto:feedback@pushinglimits.club", "_system");
          },
          onRateDialogShow: function (callback) {
            callback(1); // cause immediate click on 'Rate Now' button
          },
          onButtonClicked: function (buttonIndex) {
            console.log("onButtonClicked -> " + buttonIndex);
          },
        },
      });

      AppRate.promptForRating(false);
    },

    */
    async prepareStores() {
      window.store = window.CdvPurchase.store;
      const _self = this;
      if (!this.isPrepared && window.store) {
        this.isPrepared = true;
        document.removeEventListener("deviceready", this.prepareStores);

        try {
          console.log("Prepare....");

          this.appstoreState = {
            error: "",
            status: "Lade...",
            product1: { offers: [] },
            product2: { offers: [] },
            product3: { offers: [] },
          };

          window.store.verbosity = window.CdvPurchase.LogLevel.WARNING;

          window.store.autoFinishTransactions = true;

          if (this.platform == "android") {
            // Setup the receipt validator service.
            // window.store.validator ="https://backend.pushinglimits.club/iap/validate2";
          }

          window.store.error(function (error) {
            console.log("Store error", error);
            _self.appstoreState.error = `${error.message}`; // Fehler ${error.code}:
          });

          window.store.ready(function () {
            console.log("IAP store ready");
          });

          // Called when any subscription product is updated

          window.store.when().productUpdated(async function () {
            _self.appstoreState.product1 = window.store.get("founding_member");
            _self.appstoreState.product2 = window.store.get("yearly_member");
            _self.appstoreState.product3 = window.store.get("half_year_member");
          });

          window.store
            .when()
            .approved(async (p) => {
              await _self.verifyIfLoggedInOrWait(p);
            })
            .verified(async (p) => {
              if (this.platform == "ios") {
                await axios.post(_self.$host + "/iap/apple_check_subscription", {
                  data: p,
                });
              }

              if (this.platform == "android") {
                await axios.post(_self.$host + "/iap/google_check_subscription", {
                  data: p,
                });
              }

              p.finish();
            })
            .finished(() => {
              console.log("FINISHED LOG");
            });

          let products = [];
          if (this.platform == "ios") {
            products.push({
              id: "founding_member",
              type: window.store.PAID_SUBSCRIPTION, // PAID_SUBSCRIPTION,
              platform: "ios-appstore",
            });
            products.push({
              id: "yearly_member",
              type: window.store.PAID_SUBSCRIPTION, // PAID_SUBSCRIPTION,
              platform: "ios-appstore",
            });
            products.push({
              id: "half_year_member",
              type: window.store.PAID_SUBSCRIPTION, // PAID_SUBSCRIPTION,
              platform: "ios-appstore",
            });
          }

          if (this.platform == "android") {
            products.push({
              id: "founding_member",
              type: window.store.PAID_SUBSCRIPTION, // PAID_SUBSCRIPTION,
              platform: "android-playstore",
            });
            products.push({
              id: "yearly_member",
              type: window.store.PAID_SUBSCRIPTION, // PAID_SUBSCRIPTION,
              platform: "android-playstore",
            });
            products.push({
              id: "half_year_member",
              type: window.store.PAID_SUBSCRIPTION, // PAID_SUBSCRIPTION,
              platform: "android-playstore",
            });
          }

          // We should first register all our products or we cannot use them in the app.
          window.store.register(products);

          this.store = window.store;

          window.store.initialize();
        } catch (err) {
          console.log("Fehler!" + err, err);
        }
      }
    },

    logError(error, context = "") {
      console.error(`Error in ${context}: ${error}`, error);
      console.error("Error stack:", error.stack);
    },

    setScrollLock(lockIt) {
      const el = window.document.body;

      if (lockIt) {
        el.classList.add("locked");
      } else {
        // Remove both classes to ensure scroll is re-enabled
        el.classList.remove("locked");
        el.classList.remove("scroll-bounce-lock");
      }
    },

    setActiveKraftraumWorkout(isActive) {
      this.activeKraftraumWorkout = isActive;
    },

    returnToWorkout() {
      this.$router.push('/kraftraum');
    }
  },

  created: async function () {
    // Check authentication state on app start
    const token = await storageHelper.getItem("token");
    const user = await storageHelper.getItem("user");
    
    if (token && user) {
      console.log("AUTH TOKEN AND USER RESTORE");

      // Set axios auth header
      axios.defaults.headers.common["Authorization"] = token;
      if (window && window.cordova) {
        window.cordova.plugin.http.setHeader("Authorization", token);
      }

      try {
        // Refresh user data in background
        await this.$store.dispatch("refresh_user");
        console.log("USER REFRESHED");
      } catch (err) {
        console.error("Error refreshing user data:", err);
      }
    }

    this.loadCyclingState();

    this.platform = Capacitor.getPlatform();
    if (Capacitor.getPlatform() != "web" && Capacitor.getPlatform() != "electron") {
      console.log("STORE PREPARE");

      document.addEventListener("deviceready", this.prepareStores);
      // document.addEventListener("deviceready", this.loadAppleWatchWorkouts);
      // document.addEventListener("deviceready", this.appRate(), false);
    }

    if (Capacitor.getPlatform() == "ios") {
      try {
      const res = await axios.get(`${this.$host}/healthkit/is_connected`);
      const healthkitConnected = res.data.connected;

      if (healthkitConnected) {
        // Start observing workouts
        await AppleWorkoutPlugin.requestWorkoutKitAuthorization();

        await AppleWorkoutPlugin.startObservingWorkouts();

        let importedWorkoutIds = [];

        // Listen for workout updates
        AppleWorkoutPlugin.addListener("workoutsUpdated", async (data) => {
          console.log("Observer New workouts:", data.workouts);
          // import all new workouts
          for (let workout of data.workouts) {
            try {
              if (!importedWorkoutIds.includes(workout.id)) {
                await AppleHealthService.importWorkout(workout);
                console.log("Successfully imported workout:", workout.id);
                importedWorkoutIds.push(workout.id);
                // await NotificationService.showWorkoutImportedNotification();
              }
            } catch (error) {
              console.error("Error importing workout: " + error, error);
            }
          }
        });
      }
      } catch (error) {
        console.error("Error checking healthkit connection: " + error, error);
      }
    }
  },

  mounted: async function () {
    const _self = this;

    this.appstoreState = {
      error: "",
      status: "Lade...",
      product1: { offers: [] },
      product2: { offers: [] },
      product3: { offers: [] },
    };

    var loader = function () {
      var s = document.createElement("script"),
        tag = document.getElementsByTagName("script")[0];
      s.src = "https://cdn.iubenda.com/iubenda.js";
      tag.parentNode.insertBefore(s, tag);
    };
    if (window.addEventListener) {
      window.addEventListener("load", loader, false);
    } else if (window.attachEvent) {
      window.attachEvent("onload", loader);
    } else {
      window.onload = loader;
    }

    var loader2 = function () {
      var s = document.createElement("script"),
        tag = document.getElementsByTagName("script")[0];
      s.src = "https://cdn.iubenda.com/iubenda.js";
      tag.parentNode.insertBefore(s, tag);
    };
    if (window.addEventListener) {
      window.addEventListener("load", loader2, false);
    } else if (window.attachEvent) {
      window.attachEvent("onload", loader2);
    } else {
      window.onload = loader2;
    }

    if (Capacitor.getPlatform() == "ios") {
      if (Capacitor.isPluginAvailable("App")) {
        App.addListener("appUrlOpen", (event) => {
          console.log(`URL: ${event.url}`);
          try {
            const slug = event.url.split(".club/#").pop();

            console.log("appUrlOpen", slug);

            // We only push to the route if there is a slug present
            if (slug) {
              _self.$router.push({
                path: slug,
              });
            }
          } catch (err) {
            console.log("appUrlOpen err", err);
          }
        });
      }

      this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;

      // await this.syncWorkoutsToApple(); -> wird schon im Dashboard gemacht
    }
    //await this.retrieveAppleWatchWorkouts();

    // Add URL handling for both iOS and Android
    if (Capacitor.isPluginAvailable("App")) {
      App.addListener("appUrlOpen", (event) => {
        console.log(`URL opened: ${event.url}`);
        try {
          // Handle URLs for both platforms
          let slug;
          if (event.url.includes(".club/#")) {
            // iOS format
            slug = event.url.split(".club/#").pop();
          } else if (event.url.includes("?link=")) {
            // Android format - extract the path after ?link=
            const params = new URLSearchParams(event.url.split("?")[1]);
            slug = params.get("link");
            if (slug && slug.startsWith("/")) {
              slug = slug.substring(1);
            }
          }

          console.log("Extracted slug:", slug);

          // We only push to the route if there is a slug present
          if (slug) {
            _self.$router.push({
              path: slug,
            });
          }
        } catch (err) {
          console.log("Error handling appUrlOpen:", err);
        }
      });
    }
  },

  errorCaptured(err, vm) {
    this.logError(err, `ErrorCaptured in ${vm.$options.name || "unnamed component"}`);
    return false; // Prevent the error from propagating further
  },
};
</script>

<style>
@import "./assets/css/font-awesome.css";
</style>
<style lang="scss">
$grid-columns: 14 !default;

img {
  box-shadow: rgba(13, 13, 19, 0.04) 0px 8px 46px 0px;
}

.responsive-table {
  width: 100%;
  overflow-x: auto;
}

.responsive-table table {
  width: 100%;
  min-width: 250px; /* Adjust this based on your table content */
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.mob-submenu {
  padding-left: 30px;
}

a {
  text-decoration: none !important;
}

.container-fluid {
  padding: 15px;
  padding-top: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  //background: #f1f1f3;
  background-color: #b8c6db;
  background-image: linear-gradient(270deg, #f5f7fa 0%, #f5f7fa 74%);
}

body {
  height: 100%;

  font-size: 14px;
  -webkit-font-smoothing: antialiased;

  background-position: center;
}

.copilot-btn {
  background: #fff;
  color: #000;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  margin: 0 2px;
  cursor: pointer;
  font-size: 14px;
}

.copilot-btn:hover {
  background: #000;
  color: #fff;
}

.subimg {
  box-shadow: rgba(20, 20, 28, 0.08) 0px 6px 44px 0px;
}

.week {
  background: #fff;
  margin-bottom: 30px;
  padding: 35px 15px;
  border-radius: 10px;
  color: #333;
  box-shadow: rgba(13, 13, 18, 0.1) 0px 8px 54px 0px;
}

.white_wrapper {
  padding: 45px 45px;
  padding-bottom: 50px;
  background: #fff; // rgb(247, 247, 250); // #fff;
  border-radius: 10px;
  box-shadow: rgba(20, 20, 28, 0.08) 0px 6px 44px 0px;
}

.white_wrapper_tighter {
  padding: 25px;
  background: #fff; // rgb(247, 247, 250); // #fff;
  border-radius: 10px;
  box-shadow: rgba(20, 20, 28, 0.08) 0px 1px 13px 0px;
}

.width-wrap {
  max-width: 1600px;
  margin: 0 auto;
}

.wrap {
  max-width: 1655px;
  margin: 0 auto;
  padding-bottom: 100px;
}

h2 {
  font-size: 25px;
}

h4 {
  font-size: 18px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #202020;
  margin-top: 0;

  // Remove the fixed padding-top
  // padding-top: 60px;

  // Add padding-top only when nav-bar is visible and fixed
  &:has(#nav-bar.nav-bar-fixed) {
    padding-top: calc(60px + env(safe-area-inset-top));
  }

  // Keep the existing rule for when nav-bar is hidden
  &:has(#nav-bar[style*="display: none"]) {
    padding-top: 0;
  }
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

.sticky-top {
  top: 100px;

  z-index: 10;
}

.button {
  font-size: 20px;
  color: #fff;
  background: rgba(94, 110, 255, 0.97);
  /*box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);*/
  border-radius: 45px;
  padding: 5px 20px;
}

.button:hover {
  filter: brightness(80%);
}

.padded {
  padding: 20px;
}

.clean-heading {
  font-size: 1.1rem;
}

.centered {
  text-align: center;
}

.overlayed {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(50, 50, 50, 0.4);
  margin: 0 auto;
}

.bottom-button-active-cycling {
  position: fixed;

  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  padding: 19px 10px;
  box-shadow: rgba(20, 20, 28, 0.15) 0px 8px 34px 0px;
  border-radius: 50%;
  z-index: 1400;
  font-weight: bold;

  background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
}

.border-gradient {
  border-width: 7px;
  border-style: solid;
  border-image: linear-gradient(to right, darkblue, darkorchid) 1;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
}

.no-border-gradient {
  border-width: 7px;
  border-style: solid;
  border-color: #eee;
  padding: 20px;
  text-align: left;
}

.bottom-button-three {
  position: fixed;
  left: 15px;
  bottom: 159px;
  width: 60px;
  height: 60px;
  padding: 19px 10px;
  box-shadow: rgba(20, 20, 28, 0.15) 0px 8px 34px 0px;
  border-radius: 50%;
  z-index: 900;
  font-weight: bold;
  background: rgba(250, 250, 250, 1);
}

.bottom-button-two {
  position: fixed;
  left: 15px;
  bottom: 87px;
  width: 60px;
  height: 60px;
  padding: 19px 10px;
  box-shadow: rgba(20, 20, 28, 0.15) 0px 8px 34px 0px;
  border-radius: 50%;
  z-index: 900;
  font-weight: bold;
  background: rgba(250, 250, 250, 1);
}

.bottom-button {
  position: fixed;
  left: 15px;
  bottom: 15px;
  width: 60px;
  height: 60px;
  padding: 19px 10px;
  box-shadow: rgba(20, 20, 28, 0.15) 0px 8px 34px 0px;
  border-radius: 50%;
  z-index: 900;
  font-weight: bold;
  background: rgba(250, 250, 250, 1);
}

.back-button {
  position: fixed;
  cursor: pointer;
  font-size: 24px;
  transform: rotate(180deg);
  left: 8px;
  bottom: 8px;
  width: 60px;
  height: 60px;
  padding: 12px 0px;
  border-radius: 50%;
  z-index: 500;
  background: rgba(250, 250, 250, 0.9);
  box-shadow: rgba(20, 20, 28, 0.085) 0px 8px 34px 0px;
}

.button i {
  color: #fff !important;
}

#wrapper {
  padding: 35px;
}

#nav-bar {
  left: 0;
  right: 0;
  z-index: 1001;
  padding-top: env(safe-area-inset-top);

  background: rgb(247, 247, 250);
  box-shadow: rgba(13, 13, 18, 0.1) 0px 2px 24px 0px;

  &.nav-bar-fixed {
    position: fixed;
    top: 0;
    // Add height calculation including safe area
    height: calc(60px + env(safe-area-inset-top));
  }
}

.submenu {
  padding-bottom: 25px;
}

.active_submenu {
  //background: rgb(100, 149, 237) none repeat scroll 0% 0% !important;
  color: rgba(94, 110, 255, 0.97) !important;
  // border-radius: 10px;
}

select {
  text-align: center;
  padding: 4px 8px;
  border: 1px solid #ccc;
  height: 40px;
  color: #202020;
  background: transparent;
  font-size: 16px;
  border-radius: 10px;
}

input {
  text-align: center;
  padding: 4px;
  border: 1px solid #ccc;
  height: 40px;
  color: #202020;
  background: transparent;
  font-size: 16px;
  border-radius: 10px;
  max-width: 100%;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

input[type="checkbox"] {
  min-width: 15px;
}

textarea {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: transparent;
  padding: 6px;
  border: 1px solid #ccc;
  min-width: 90%;
  color: #202020;
  font-size: 16px;
}

a {
  cursor: pointer;
}

.btn {
  border-radius: 10px;
}

.btn:hover {
  color: #fff;
}

.a-button {
  margin: 0 5px;
  padding: 8px 50px;
  background: #000;
  min-width: 150px;
  color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
}

.a-button:hover {
  filter: brightness(90%);
}

.b-button {
  margin: 0 5px;
  padding: 8px 20px;
  background: #fff;
  min-width: 150px;
  color: #000;
  border: 2px solid #000;
  border-radius: 10px;
  cursor: pointer;
  min-width: 150px;
}

.b-button:hover {
  text-decoration: none;
  background: #000;
  color: #fff;
  filter: brightness(90%);
}

.a-button:hover {
  text-decoration: none;
  color: #fff;
}

button {
  padding: 8px 0;
  padding-bottom: 7px;
  background: #314469;
  min-width: 140px;
  max-width: 100%;
  color: #fff;
  border: 2px solid #314469;
  border-radius: 10px;
  font-weight: bold;
}

button:hover {
  filter: brightness(80%);
}

button:not(:active),
a:not(:active) {
  /* now keep red background for 1s */
  transition: opacity 200ms step-end;
}

button:active,
a:active {
  opacity: 0.8;
}

.align-right {
  text-align: right;
}

.secondary {
  background: #2f4858;
  color: #fff;
  border: 2px solid #2f4858;
}

.secondary:hover {
  filter: brightness(80%);
}

.thirdary {
  background: rgb(212, 116, 4);
  color: #fff;
  border: 2px solid rgb(212, 116, 4);
  padding: 8px 20px;
}

.thirdary:hover {
  filter: brightness(80%);
}

.delete {
  background: transparent;
  color: #fff;
  background: darkred;
  border: 2px solid darkred;
}
.delete:hover {
  filter: brightness(80%);
}

.closer {
  background: transparent;
  color: #fff;
  background: #009c8f;
  border: 2px solid #009c8f;
}
.closer:hover {
  filter: brightness(80%);
}
.logo {
  width: 46px;

  margin-left: 20px;
}

.top-spacer-100 {
  margin-top: 100px;
}

.top-spacer-75 {
  margin-top: 75px;
}

.top-spacer-60 {
  margin-top: 60px;
}

.top-spacer-50 {
  margin-top: 50px;
}

.top-spacer-25 {
  margin-top: 25px;
}

.top-spacer-15 {
  margin-top: 15px;
}

.top-spacer-20 {
  margin-top: 20px;
}

.top-spacer-10 {
  margin-top: 10px;
}

.top-spacer-5 {
  margin-top: 5px;
}

h1 {
  font-size: 3.5rem;
  text-align: left;
}

.colored-row:nth-child(even) {
  background: #f3f3f3;
}

.menu {
  background: #fff;

  padding: 20px;
  font-size: 1.3rem;
  color: #333;
  padding-right: 3px;
  z-index: 200000000;
  border-bottom: 1px solid #f3e3e3;

  a {
    text-decoration: none;
    display: inline-block;
    color: #333;
    font-size: 18px;
    padding: 5px 35px;
    text-align: center;

    &.router-link-exact-active {
      color: #000;
      font-weight: bold;
    }
  }
}

.highlight {
  background: #222;
  color: #fff;
  padding: 0.3rem 1.5rem;
  line-height: 2;
  border-radius: 10px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.highlight-short {
  background: #222;
  color: #fff;
  padding: 0.3rem 1.5rem;
  line-height: 2;
  margin: 0 15px;
  border-radius: 10px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.router-link-exact-active {
  color: #000;
  font-weight: bold;
}

.mobile-router-link-exact-active {
  color: blue !important;
  font-weight: bold;
}

.spacer-bar {
  margin: 45px 0;
  width: 100%;
  height: 8px;
  background: #eee;
}

.staydreaming {
  letter-spacing: 0.015rem;
}

.block {
  position: relative;
  background: #000;
  padding: 5px 10px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
.block:hover {
  background: #000;
  color: #fff;
}

button:disabled {
  display: none;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.pointer {
  cursor: pointer;
}

.styled-table {
  border-collapse: collapse;
  width: 100%;
  overflow-y: auto;
  text-align: left;
  border-radius: 10px;
}

.styled-table-no-bottom {
  box-shadow: 0 0 0 !important;
  border-bottom: 0 !important;
  border-radius: 10px;
}

.styled-table .colored-heading {
  background: #fff;
  padding: 15px;
  font-weight: bold;
}

.chart-wrapper {
  padding: 20px;
}

.styled-table .colored-heading i {
  margin-top: -13px;
}

.chart-wrapper-tighter {
  padding: 5px 5px;
  overflow-x: hidden;
}
table,
.styled-table,
.styled-table thead,
.styled-table thead tr {
  background: #fff;
}

.styled-table thead tr,
.styled-table .colorful-heading {
  // linear-gradient(to right,#de54aa,#9f44fd)!important;
  font-weight: bold;
}

.styled-table th,
.styled-table td,
.styled-table .colorful-heading {
  padding: 12px 11px;
}

.styled-table tbody tr {
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #fff;
}

.styled-table .colored-row:nth-of-type(even) {
  background-color: #fff;
}

.extra-styled-table > .row:nth-of-type(odd) {
  background-color: #fff;
}
.extra-styled-table .row:last-of-type {
}

.light-shadow {
  box-shadow: rgba(13, 13, 19, 0.04) 0px 8px 46px 0px !important;
}

.extra-styled-table .row {
  padding: 12px 0px;
  margin: 0 0px;
}

.bar {
  background: #000;
  height: 15px !important;
  display: table;
  line-height: 15px;
}

.menu {
  padding: 15px;
}

.second-menu {
  background: #fff;
  color: #999;
  border-radius: 3px;
}

.menu-entry {
  font-size: 18px;
  padding: 12px 0;
  font-weight: bold;
  cursor: pointer;
  color: #444;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.menu-entry:hover {
  color: #000;
}

.pointer {
  cursor: pointer;
}

.centered {
  text-align: center;
}

.dropdown {
  cursor: pointer;
  z-index: 25000;
  overflow-y: scroll;
}

.dropdown-content {
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: -2px;
  top: 63px;
  font-size: 1.1rem;
  position: fixed;
  //background-color: #ffffff;
  //background-image: linear-gradient(320deg, #ffffff 0%, #5899e2 70%);
  background-image: linear-gradient(315deg, #fff, #d7e1ec 80%);
  width: 100%;
  padding: 20px 15px;
  z-index: 100000000;
  color: #222;
  border-bottom: 2px solid #ccc;
  border-top: 1px solid #ccc;
  overflow-y: scroll;
  padding-top: calc(env(safe-area-inset-top) + 20px);
  padding-left: calc(env(safe-area-inset-left) + 20px);

  a {
    color: #000;
    padding-top: 3px;
    padding-bottom: 3px;
    display: block;

    &.router-link-exact-active {
      border-bottom: 0;
    }
  }
}

.col-xs-7ths,
.col-sm-7ths,
.col-md-7ths,
.col-lg-7ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-7ths {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.pull-right-non-mobile {
  float: right;
}

.content_wrapper {
  padding: 3px 45px;
  margin-top: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(20, 20, 28, 0.08) 0px 6px 44px 0px;
}

.white_wrapper {
  padding: 35px 15px;
  padding-bottom: 50px;
}

@media (max-width: 766px) {
  .col-xs-7ths {
    margin: auto;
  }
}

@media (max-width: 768px) {
  .col-xs-7ths {
    max-width: 420px;
  }
  .col-sm-7ths {
    max-width: 420px;
  }

  .col-md-7ths {
    padding-right: 5px;
    padding-left: 5px;
    position: relative;
    min-height: 1px;
  }

  .content_wrapper {
    padding: 3px 5px;
    margin-top: 10px;
    background: #fff;
    border-radius: 8px;
  }
}

@media (max-width: 1200px) {
  .mobile-centered {
    text-align: center;
  }
  .pull-right-non-mobile {
    float: none;
  }
  .submenu {
    position: relative;
  }
  .col-md-7ths {
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .active_submenu {
    background: rgb(100, 149, 237) none repeat scroll 0% 0%;
    color: #fff !important;
    border-radius: 10px;
    text-align: center;
  }

  .menu-entry {
    border: 0;
    padding: 6px 0;
    text-align: center;
  }

  .menu {
    a {
      padding: 5px 20px !important;
    }
  }
}

@media (max-width: 576px) {
  .padded {
    padding: 5px;
  }
  .col-sm-7ths {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) {
  .col-sm-7ths {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .col-md-7ths {
    width: 14.28%;
  }

  .white_wrapper {
    padding: 45px 45px;
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .desktop-left {
    text-align: left;
  }
  .col-lg-7ths {
    width: 14.28%;
    float: left;
  }

  .padded {
    padding: 40px;
  }
}

@media (min-width: 1685px) {
  .desktop-border-radius {
    border-radius: 10px;
  }
}

._vue-flash-msg-body {
  z-index: 9990000000 !important;
  padding: 10px;
}
._vue-flash-msg-body__title {
  font-size: 16px;
}
._vue-flash-msg-body_error {
  background-color: rgba(241, 34, 34, 0.92) !important;
}

/* Tooltip container */
.tooltip {
  text-align: center;
  display: inline-block;
  position: absolute;
  opacity: 1;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 10px 10px;
  border-radius: 6px;
  white-space: pre-wrap;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1000;
  width: 240px;
  top: 100%;
  left: 50%;
  margin-left: -140px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  z-index: 1000000000;
}

.locked {
  overflow: hidden !important;
  height: 100%;
}
.scroll-bounce-lock {
  /* -webkit-overflow-scrolling: touch !important;*/
  overflow: auto !important;
  height: 100%;
}

.segment-block {
  cursor: pointer;
}
.segment-block:hover {
  background: #eee;
}

blockquote.DE {
  background: linear-gradient(to right, #202020 4px, transparent 4px) 0% 0%,
    linear-gradient(to bottom, #202020 4px, transparent 4px) 0% 0%,
    linear-gradient(to left, #202020 4px, transparent 4px) 100% 100%,
    linear-gradient(to top, #202020 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

blockquote.quote {
  position: relative;
  text-align: center;
  padding: 1rem 1.2rem;
  width: 80%;
  color: #202020;
  margin: 1rem auto 2rem;
  font-size: 20px;
}

blockquote.quote:before,
blockquote.quote:after {
  font-family: FontAwesome;
  position: absolute;
  color: #202020;
  font-size: 34px;
}

blockquote.DE:before {
  content: "\f10e";
  margin-right: -20px;
  bottom: -20px;
  right: 100%;
}

blockquote.DE:after {
  content: "\f10d";
  margin-left: -20px;
  left: 100%;
  top: -20px;
  bottom: auto;
}

.bottom-button-active-kraftraum {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  padding: 19px 10px;
  box-shadow: rgba(20, 20, 28, 0.15) 0px 8px 34px 0px;
  border-radius: 50%;
  z-index: 1400;
  font-weight: bold;
  background: linear-gradient(120deg, #0d6efd 0%, #0dcaf0 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add hover effect */
.bottom-button-active-kraftraum:hover {
  transform: translateY(-2px);
  box-shadow: rgba(20, 20, 28, 0.25) 0px 10px 40px 0px;
}
</style>

