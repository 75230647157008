//import {timeout} from './lib/utils';

let ble_sint16 = ["getInt16", 2, true];
let ble_uint8 = ["getUint8", 1];
let ble_uint16 = ["getUint16", 2, true];
let ble_uint32 = ["getUint32", 4, true];
// TODO: paired 12bit uint handling
let ble_uint24 = ["getUint8", 3];

// https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.characteristic.cycling_power_measurement.xml
let cycling_power_measurement = [
  [0, [[ble_sint16, "instantaneous_power"]]],
  [1, [[ble_uint8, "pedal_power_balance"]]],
  [
    2,
    [
      /* Pedal Power Balance Reference */
    ],
  ],
  [4, [[ble_uint16, "accumulated_torque"]]],
  [
    8,
    [
      /* Accumulated Torque Source */
    ],
  ],
  [
    16,
    [
      [ble_uint32, "cumulative_wheel_revolutions"],
      [ble_uint16, "last_wheel_event_time"],
    ],
  ],
  [
    32,
    [
      [ble_uint16, "cumulative_crank_revolutions"],
      [ble_uint16, "last_crank_event_time"],
    ],
  ],
  [
    64,
    [
      [ble_sint16, "maximum_force_magnitude"],
      [ble_sint16, "minimum_force_magnitude"],
    ],
  ],
  [
    128,
    [
      [ble_sint16, "maximum_torque_magnitude"],
      [ble_sint16, "minimum_torque_magnitude"],
    ],
  ],
  [256, [[ble_uint24, "maximum_minimum_angle"]]],
  [512, [[ble_uint16, "top_dead_spot_angle"]]],
  [1024, [[ble_uint16, "bottom_dead_spot_angle"]]],
  [2048, [[ble_uint16, "accumulated_energy"]]],
  [
    4096,
    [
      /* Offset Compensation Indicator */
    ],
  ],
];

let ftms_measurement = [
  [1, [[ble_uint16, "more_data"]]],          // More Data Flag (0x01)
  [2, [[ble_uint16, "instantaneous_speed"]]], // Instantaneous Speed, resolution of 0.01 km/h
  [4, [[ble_uint16, "average_speed"]]],       // Average Speed
  [8, [[ble_uint16, "instantaneous_cadence"]]], // Instantaneous Cadence, resolution of 0.5 rpm
  [16, [[ble_sint16, "instantaneous_power"]]], // Instantaneous Power
  [32, [[ble_uint16, "average_power"]]],      // Average Power
  [64, [[ble_uint16, "total_energy"]]],       // Total Energy
  [128, [[ble_uint16, "energy_per_hour"]]],   // Energy Per Hour
  [256, [[ble_uint8, "energy_per_minute"]]],  // Energy Per Minute
  [512, [[ble_sint16, "heart_rate"]]],        // Heart Rate
  [1024, [[ble_uint8, "metabolic_equivalent"]]], // Metabolic Equivalent
  [2048, [[ble_uint16, "elapsed_time"]]],     // Elapsed Time
  [4096, [[ble_uint16, "remaining_time"]]]    // Remaining Time
];
/*
const UUIDS = {
  service: 'fitness_machine',
  characteristic: {
    fitnessMachineFeature: 'fitness_machine_feature', // 0x2ACC
    fitnessMachineControlPoint: 'fitness_machine_control_point',//0x2AD9
  }
 
};

const fitnessMachineFeaturesFields = {
  averageSpeedSupported: 0,
  cadenceSupported: 1,
  totalDistanceSupported: 2,
  inclinationSupported: 3,
  elevationGainSupported: 4,
  paceSupported: 5,
  stepCountSupported: 6,
  resistanceLevelSupported: 7,
  strideCountSupported: 8,
  expendedEnergySupported: 9,
  heartRateMeasurementSupported: 10,
  metabolicEquivalentSupported: 11,
  elapsedTimeSupported: 12,
  remainingTimeSupported: 13,
  powerMeasurementSupported: 14,
  forceonBeltandPowerOutputSupported: 15,
  userDataRetentionSupported: 16,
};
const targetSettingsFields = {
  speedTargetSettingSupported: 0,
  inclinationTargetSettingSupported: 1,
  resistanceTargetSettingSupported: 2,
  powerTargetSettingSupported: 3,
  heartRateTargetSettingSupported: 4,
  targetedExpendedEnergyConfigurationSupported: 5,
  targetedStepNumberConfigurationSupported: 6,
  targetedStrideNumberConfigurationSupported: 7,
  targetedDistanceConfigurationSupported: 8,
  targetedTrainingTimeConfigurationSupported: 9,
  targetedTimeinTwoHeartRateZonesConfigurationSupported: 10,
  targetedTimeinThreeHeartRateZonesConfigurationSupported: 11,
  targetedTimeinFiveHeartRateZonesConfigurationSupported: 12,
  indoorBikeSimulationParametersSupported: 13,
  wheelCircumferenceConfigurationSupported: 14,
  spinDownControlSupported: 15,
  targetedCadenceConfigurationSupported: 16,
};
*/

// https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.characteristic.csc_measurement.xml
let csc_measurement = [
  [
    1,
    [
      [ble_uint32, "cumulative_wheel_revolutions"],
      [ble_uint16, "last_wheel_event_time"],
    ],
  ],
  [
    2,
    [
      [ble_uint16, "cumulative_crank_revolutions"],
      [ble_uint16, "last_crank_event_time"],
    ],
  ],
];

class BleCharacteristicParser {
  getData(dataview) {
    let offset = 0;
    let mask;
    if (this.mask_size === 16) {
      mask = dataview.getUint16(0, true);
      offset += 2;
    } else {
      mask = dataview.getUint8(0);
      offset += 1;
    }

    let fieldArrangement = [];

    // Contains required fields
    if (this.fields[0][0] === 0) {
      for (let fdesc of this.fields[0][1]) {
        fieldArrangement.push(fdesc);
      }
    }

    for (let [flag, fieldDescriptions] of this.fields) {
      if (mask & flag) {
        for (let fdesc of fieldDescriptions) {
          fieldArrangement.push(fdesc);
        }
      }
    }

    let data = {};
    for (let field of fieldArrangement) {
      var [[accessor, fieldSize, endianness], fieldName] = field;
      let value;
      if (endianness) {
        value = dataview[accessor](offset, endianness);
      } else {
        value = dataview[accessor](offset);
      }

      data[fieldName] = value;
      offset += fieldSize;
    }

    return data;
  }
}

export class CyclingSpeedCadenceMeasurementParser extends BleCharacteristicParser {
  constructor() {
    super();
    this.fields = csc_measurement;
    this.mask_size = 8;
  }
}

export class CyclingPowerMeasurementParser extends BleCharacteristicParser {
  constructor() {
    super();
    this.fields = cycling_power_measurement;
    this.mask_size = 16;
  }
}

export class FTMSMeasurementParser extends BleCharacteristicParser {
  constructor() {
    super();
    this.fields = ftms_measurement;
    this.mask_size = 16;
  }
}

export class Meter {
  constructor() {
    this.listeners = {};
    this.timeoutID = undefined;
    this.milliTimeout = 8000;
  }

  clearValueOnTimeout(value) {
    if (this.timeoutID !== undefined) {
      clearTimeout(this.timeoutID);
    }
    this.timeoutID = setTimeout(() => {
      this.timeoutID = undefined;
      if (value.constructor === Array) {
        for (let v of value) {
          this.dispatch(v, 0);
        }
      } else {
        this.dispatch(value, 0);
      }
    }, this.milliTimeout);
  }

  clearListeners() {
    this.listeners = {};
  }

  addListener(type, callback) {
    if (!(type in this.listeners)) {
      this.listeners[type] = [];
    }

    this.listeners[type].push(callback);
  }

  dispatch(type, value, id) {
    if (!(type in this.listeners)) {
      this.listeners[type] = [];
    }

    for (let l of this.listeners[type]) {
      l(value, id);
    }
  }
}

export class BleMeter extends Meter {
  constructor(device, server, service, characteristic) {
    super();

    this.device = device;
    this.server = server;
    this.service = service;
    this.characteristic = characteristic;

    this.name = this.device.name;
    this.id = this.device.id;

    this.listening = false;

    this.device.addEventListener("gattserverdisconnected", (e) => {
      this.gattserverdisconnected(e).catch((error) => {
        console.log("Error: ", error);
      });
    });
  }

  async gattserverdisconnected() {
    console.log("Reconnecting");
    this.server = await this.device.gatt.connect();
    this.service = await this.server.getPrimaryService(this.serviceId);
    this.characteristic = await this.service.getCharacteristic(
      this.characteristicId
    );
    if (this.listening) {
      this.listening = false;
      this.listen();
    }
  }
}

// Add these constants at the top of the file
const WHEEL_CIRCUMFERENCE = 2.105; // meters (700c wheel with 23mm tire)
const MAX_REALISTIC_CADENCE = 200; // rpm
const MIN_REALISTIC_CADENCE = 20; // rpm when pedaling

// Add these constants at the top of the file, after the existing constants
const CADENCE_SMOOTHING_FACTOR = 0.3; // For exponential smoothing
const MAX_CADENCE_CHANGE = 30; // Max reasonable change between readings
const CADENCE_TIMEOUT_MS = 3000; // Time before considering cadence as 0

// Add helper function to calculate speed from wheel RPM
function wheelRpmToSpeed(wheelRpm) {
    // Convert wheel RPM to speed in km/h
    return (wheelRpm * WHEEL_CIRCUMFERENCE * 60) / 1000;
}

// Add helper function to estimate speed from power
function estimateSpeedFromPower(power) {
    // Basic estimation using P = k * v^3 relationship
    // This is a simplified model - could be made more accurate
    const k = 0.125; // Air resistance coefficient (simplified)
    let speed = Math.pow(power / k, 1/3);
    return speed * 3.6; // Convert m/s to km/h
}

// Add validation function for cadence
function validateCadence(rpm, previousRpm) {
    if (rpm === 0 || rpm === undefined) return 0;
    
    // Basic range check
    if (rpm < MIN_REALISTIC_CADENCE || rpm > MAX_REALISTIC_CADENCE) {
        console.warn(`Suspicious cadence value filtered: ${rpm} RPM`);
        return previousRpm || 0;
    }

    // Check for unrealistic jumps if we have a previous value
    if (previousRpm !== undefined && previousRpm !== null) {
        const change = Math.abs(rpm - previousRpm);
        if (change > MAX_CADENCE_CHANGE) {
            console.warn(`Suspicious cadence jump filtered: ${previousRpm} -> ${rpm}`);
            return previousRpm;
        }
        
        // Apply exponential smoothing
        return previousRpm + CADENCE_SMOOTHING_FACTOR * (rpm - previousRpm);
    }

    return rpm;
}

export class BlePowerCadenceMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x1818;
    this.characteristicId = 0x2a63;
    this.parser = new CyclingPowerMeasurementParser();

    this.lastCrankRevolutions = 0;
    this.lastCrankTime = 0;
    this.lastWheelRevolutions = 0;
    this.lastWheelTime = 0;
    this.lastValidCadence = 0;
    
    // Add FTMS characteristic for direct cadence reading
    this.ftmsCharacteristic = null;
    this.ftmsParser = new FTMSMeasurementParser();
    this.hasFTMS = false;
  }

  async initialize() {
    try {
      // Try to get FTMS characteristic
      const ftmsService = await this.server.getPrimaryService(0x1826);
      this.ftmsCharacteristic = await ftmsService.getCharacteristic(0x2ACD);
      this.hasFTMS = true;
      console.log("FTMS characteristic found - will use direct cadence reading");
    } catch (error) {
      console.log("No FTMS characteristic found - using calculated cadence");
      this.hasFTMS = false;
    }
  }

  async listen() {
    if (!this.listening) {
      // Initialize FTMS if not done yet
      if (this.ftmsCharacteristic === null) {
        await this.initialize();
      }

      // Set up FTMS listener if available
      if (this.hasFTMS) {
        this.ftmsCharacteristic.addEventListener(
          "characteristicvaluechanged",
          (event) => {
            let data = this.ftmsParser.getData(event.target.value);
            if (data["instantaneous_cadence"] !== undefined) {
              let cadence = data["instantaneous_cadence"] / 2; // Convert from 0.5 rpm to rpm
              cadence = validateCadence(cadence, this.lastValidCadence);
              if (cadence !== null) {
                this.lastValidCadence = cadence;
                this.dispatch("cadence", cadence, this.id);
              }
            }
          }
        );
        await this.ftmsCharacteristic.startNotifications();
      }

      // Set up power meter listener
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let data = this.parser.getData(event.target.value);
          
          let power = data["instantaneous_power"];
          let crankRevolutions = data["cumulative_crank_revolutions"];
          let crankTime = data["last_crank_event_time"];
          let wheelRevolutions = data["cumulative_wheel_revolutions"];
          let wheelTime = data["last_wheel_event_time"];

          // Only calculate cadence if FTMS is not available
          if (!this.hasFTMS && crankRevolutions !== undefined && crankTime !== undefined) {
            if (this.lastCrankTime > crankTime) {
              this.lastCrankTime = this.lastCrankTime - 65536;
            }
            if (this.lastCrankRevolutions > crankRevolutions) {
              this.lastCrankRevolutions = this.lastCrankRevolutions - 65536;
            }

            let revs = crankRevolutions - this.lastCrankRevolutions;
            let duration = (crankTime - this.lastCrankTime) / 1024;
            let rpm = 0;
            if (duration > 0) {
              rpm = (revs / duration) * 60;
              rpm = validateCadence(rpm, this.lastValidCadence);
              if (rpm !== null) {
                this.lastValidCadence = rpm;
                this.dispatch("cadence", rpm, this.id);
              }
            }

            this.lastCrankRevolutions = crankRevolutions;
            this.lastCrankTime = crankTime;
          }

          /* Speed Calc */
          let speed = 0;
          if (wheelRevolutions !== undefined && wheelTime !== undefined) {
            if (this.lastWheelTime > wheelTime) {
              this.lastWheelTime = this.lastWheelTime - 65536;
            }
            if (this.lastWheelRevolutions > wheelRevolutions) {
              this.lastWheelRevolutions = this.lastWheelRevolutions - 65536;
            }

            let wheelRevs = wheelRevolutions - this.lastWheelRevolutions;
            let wheelDuration = (wheelTime - this.lastWheelTime) / 1024;
            let wheelRpm = 0;
            if (wheelDuration > 0) {
              wheelRpm = (wheelRevs / wheelDuration) * 60;
            }

            this.lastWheelRevolutions = wheelRevolutions;
            this.lastWheelTime = wheelTime;

            speed = wheelRpmToSpeed(wheelRpm);
            this.dispatch("wheelrpm", wheelRpm, this.id);
          } else if (power > 0) {
            speed = estimateSpeedFromPower(power);
          }

          this.dispatch("power", power, this.id);
          this.dispatch("speed", speed, this.id);
          this.clearValueOnTimeout(["power", "cadence", "wheelrpm", "speed"]);
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export class BlePowerMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x1818;
    this.characteristicId = 0x2a63;
    this.parser = new CyclingPowerMeasurementParser();
  }

  listen() {
    if (!this.listening) {
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let data = this.parser.getData(event.target.value);
          let power = data["instantaneous_power"];
          this.dispatch("power", power, this.id);
          this.clearValueOnTimeout("power");
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export class BleCadenceMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x1816;
    this.characteristicId = 0x2a5b;
    this.parser = new CyclingSpeedCadenceMeasurementParser();

    this.lastCrankRevolutions = 0;
    this.lastCrankTime = 0;
    this.lastWheelRevolutions = 0;
    this.lastWheelTime = 0;

    this.lastValidCadence = 0;
    this.lastCadenceTimestamp = 0;

    // Add FTMS characteristic for direct cadence reading
    this.ftmsCharacteristic = null;
    this.ftmsParser = new FTMSMeasurementParser();
    this.hasFTMS = false;
  }

  async initialize() {
    try {
      // Try to get FTMS characteristic
      const ftmsService = await this.server.getPrimaryService(0x1826);
      this.ftmsCharacteristic = await ftmsService.getCharacteristic(0x2ACD);
      this.hasFTMS = true;
      console.log("FTMS characteristic found - will use direct cadence reading");
    } catch (error) {
      console.log("No FTMS characteristic found - using calculated cadence");
      this.hasFTMS = false;
    }
  }

  async listen() {
    if (!this.listening) {
      // Initialize FTMS if not done yet
      if (this.ftmsCharacteristic === null) {
        await this.initialize();
      }

      // Set up FTMS listener if available
      if (this.hasFTMS) {
        this.ftmsCharacteristic.addEventListener(
          "characteristicvaluechanged",
          (event) => {
            let data = this.ftmsParser.getData(event.target.value);
            if (data["instantaneous_cadence"] !== undefined) {
              let cadence = data["instantaneous_cadence"] / 2; // Convert from 0.5 rpm to rpm
              cadence = validateCadence(cadence, this.lastValidCadence);
              if (cadence !== null) {
                this.lastValidCadence = cadence;
                this.lastCadenceTimestamp = Date.now();
                this.dispatch("cadence", cadence, this.id);
              }
            }
          }
        );
        await this.ftmsCharacteristic.startNotifications();
      }

      // Set up standard cadence meter listener
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let data = this.parser.getData(event.target.value);
          let crankRevolutions = data["cumulative_crank_revolutions"];
          let crankTime = data["last_crank_event_time"];
          let wheelRevolutions = data["cumulative_wheel_revolutions"];
          let wheelTime = data["last_wheel_event_time"];
          
          const now = Date.now();

          // Only calculate cadence if FTMS is not available
          if (!this.hasFTMS && crankRevolutions !== undefined && crankTime !== undefined) {
            // Handle rollover
            if (this.lastCrankTime > crankTime) {
              this.lastCrankTime = this.lastCrankTime - 65536;
            }
            if (this.lastCrankRevolutions > crankRevolutions) {
              this.lastCrankRevolutions = this.lastCrankRevolutions - 65536;
            }

            let revs = crankRevolutions - this.lastCrankRevolutions;
            let duration = (crankTime - this.lastCrankTime) / 1024;
            
            // Only calculate if we have a reasonable duration
            let rpm = 0;
            if (duration > 0 && duration < 2.5) { // Max 2.5 seconds between readings
              rpm = (revs / duration) * 60;
              
              // Validate and smooth the cadence
              rpm = validateCadence(rpm, this.lastValidCadence);
              
              if (rpm > 0) {
                this.lastValidCadence = rpm;
                this.lastCadenceTimestamp = now;
                this.dispatch("cadence", rpm, this.id);
              }
            }

            // Check if we should zero out the cadence
            if (now - this.lastCadenceTimestamp > CADENCE_TIMEOUT_MS) {
              rpm = 0;
              this.lastValidCadence = 0;
              this.dispatch("cadence", rpm, this.id);
            }

            this.lastCrankRevolutions = crankRevolutions;
            this.lastCrankTime = crankTime;
          }

          // Handle wheel speed calculations
          let speed = 0;
          if (wheelRevolutions !== undefined && wheelTime !== undefined) {
            if (this.lastWheelTime > wheelTime) {
              this.lastWheelTime = this.lastWheelTime - 65536;
            }
            if (this.lastWheelRevolutions > wheelRevolutions) {
              this.lastWheelRevolutions = this.lastWheelRevolutions - 65536;
            }

            let wheelRevs = wheelRevolutions - this.lastWheelRevolutions;
            let wheelDuration = (wheelTime - this.lastWheelTime) / 1024;
            let wheelRpm = 0;
            if (wheelDuration > 0) {
              wheelRpm = (wheelRevs / wheelDuration) * 60;
            }

            this.lastWheelRevolutions = wheelRevolutions;
            this.lastWheelTime = wheelTime;

            speed = wheelRpmToSpeed(wheelRpm);
            this.dispatch("wheelrpm", wheelRpm, this.id);
            this.dispatch("speed", speed, this.id);
          }

          this.clearValueOnTimeout(["cadence", "wheelrpm", "speed"]);
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export class BleHRMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x180d;
    this.characteristicId = 0x2a37;
  }

  listen() {
    if (!this.listening) {
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let hr = event.target.value.getUint8(1);

          this.dispatch("hr", hr, this.id);
          this.clearValueOnTimeout("hr");
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export class VirtualPowerMeter extends Meter {
  constructor() {
    super();
    this.listening = false;
    this.watts = 0;

    this.id = "virtual";
    this.name = "Virtual Power Meter";
  }

  listen() {
    if (!this.listening) {
      setInterval(() => {
        this.dispatch("power", 10, this.id);
      }, 750);

      this.listening = true;
    }
  }
}

export class BleFTMSMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x1826;          // Fitness Machine Service
    this.characteristicId = 0x2ACD;   // Indoor Bike Data characteristic
    this.parser = new FTMSMeasurementParser();
    this.lastValidCadence = 0;
  }

  listen() {
    if (!this.listening) {
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let data = this.parser.getData(event.target.value);
          
          // Speed comes in 0.01 km/h units
          let speed = data["instantaneous_speed"] ? data["instantaneous_speed"] / 100 : 0;
          
          // Cadence comes in 0.5 rpm units
          if (data["instantaneous_cadence"] !== undefined) {
            let cadence = data["instantaneous_cadence"] / 2; // Convert from 0.5 rpm to rpm
            cadence = validateCadence(cadence, this.lastValidCadence);
            if (cadence !== null) {
              this.lastValidCadence = cadence;
              this.dispatch("cadence", cadence, this.id);
            }
          }

          // Power in watts (no conversion needed)
          let power = data["instantaneous_power"];

          if (speed !== undefined) {
            this.dispatch("speed", speed, this.id);
          }
          if (power !== undefined) {
            this.dispatch("power", power, this.id);
          }

          this.clearValueOnTimeout(["speed", "cadence", "power"]);
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

// Update the CoreTempMeasurementParser class to match specification
class CoreTempMeasurementParser {
  getData(dataView) {
    let flags = dataView.getUint8(0);
    let offset = 1;
    let data = {};

    // Parse flags
    const hasSkinTemp = (flags & 0x01) !== 0;
    const hasCoreReserved = (flags & 0x02) !== 0;
    const hasQualityAndState = (flags & 0x04) !== 0;
    const isFahrenheit = (flags & 0x08) !== 0;
    const hasHeartRate = (flags & 0x10) !== 0;
    const hasHeatStrainIndex = (flags & 0x20) !== 0;

    // Core Body Temperature (mandatory field)
    let tempRaw = dataView.getInt16(offset, true); // SINT16, little-endian
    if (tempRaw === 32767) { // 0x7FFF = data not available
      data.temperature = null;
    } else {
      data.temperature = tempRaw * 0.01; // Resolution of 0.01°C/°F
    }
    offset += 2;

    // Optional Skin Temperature
    if (hasSkinTemp) {
      data.skinTemperature = dataView.getInt16(offset, true) * 0.01;
      offset += 2;
    }

    // Optional Core Reserved
    if (hasCoreReserved) {
      data.coreReserved = dataView.getInt16(offset, true) * 0.01;
      offset += 2;
    }

    // Optional Quality and State
    if (hasQualityAndState) {
      const qualityAndState = dataView.getUint8(offset);
      
      // Parse data quality (bits 0-3)
      const quality = qualityAndState & 0x0F;
      data.quality = this.parseQuality(quality);

      // Parse state (bits 4-7)
      const state = (qualityAndState >> 4) & 0x0F;
      data.state = this.parseState(state);
      
      offset += 1;
    }

    // Optional Heart Rate
    if (hasHeartRate) {
      data.heartRate = dataView.getUint8(offset);
      offset += 1;
    }

    // Optional Heat Strain Index
    if (hasHeatStrainIndex) {
      data.heatStrainIndex = dataView.getUint8(offset) * 0.1; // Resolution of 0.1
    }

    // Add temperature unit to data
    data.temperatureUnit = isFahrenheit ? '°F' : '°C';

    return data;
  }

  parseQuality(quality) {
    switch(quality & 0x07) { // Only look at bits 0-2
      case 0: return 'Invalid';
      case 1: return 'Poor';
      case 2: return 'Fair';
      case 3: return 'Good';
      case 4: return 'Excellent';
      case 7: return 'N/A';
      default: return 'Unknown';
    }
  }

  parseState(state) {
    switch((state >> 4) & 0x03) { // Look at bits 4-5
      case 0: return 'Heart rate not supported';
      case 1: return 'Heart rate supported, no signal';
      case 2: return 'Heart rate supported, receiving signal';
      case 3: return 'N/A';
      default: return 'Unknown';
    }
  }
}

// Add CoreTemp meter class
export class BleCoreTempMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    // Use the exact UUIDs from the specification
    this.serviceId = "00002100-5B1E-4347-B07C-97B514DAE121"; // Core Body Temperature Service
    this.characteristicId = "00002101-5B1E-4347-B07C-97B514DAE121"; // Core Body Temperature Characteristic
    this.parser = new CoreTempMeasurementParser();
  }

  listen() {
    if (!this.listening) {
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          try {
            const data = this.parser.getData(event.target.value);
            
            if (data.temperature !== null) {
              this.dispatch("temperature", data.temperature, this.id);
              this.dispatch("temperatureUnit", data.temperatureUnit, this.id);
            }
            
            if (data.quality) {
              this.dispatch("quality", data.quality, this.id);
            }
            
            if (data.heartRate) {
              this.dispatch("heartRate", data.heartRate, this.id);
            }

            if (data.heatStrainIndex) {
              this.dispatch("heatStrainIndex", data.heatStrainIndex, this.id);
            }

            this.clearValueOnTimeout(["temperature", "quality", "heartRate", "heatStrainIndex"]);
          } catch (error) {
            console.error("Error parsing CoreTemp data:", error);
          }
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export { CoreTempMeasurementParser };

