<template>
  <div class="row">
    <div class="submenu col-xl-2 d-none d-md-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard">
              <div class="menu-entry">{{ $t(`Dashboard`) }}</div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/kalender">
              <div class="menu-entry active_submenu">
                {{ $t(`Kalender`) }}
              </div></a
            >
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry">{{ $t(`Trainingspläne`) }}</div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/FragDenCoach">
              <div class="menu-entry">{{ $t(`Wissen`) }}</div>
            </a>
          </div>
          <!--
            <div class="col-lg-12">
            <a href="/#/training/ai">
              <div class="menu-entry"> {{ $t(`AI-Coach`) }}</div>
            </a>
          </div>-->
        </div>
      </div>
    </div>
    <div class="col-xl-10">
      <div v-cloak @drop.prevent="addFile" @dragover.prevent @dragenter="dragEnter">
        <div id="dropzone" v-if="showDropzone">
          <h3 style="text-align: center">.FIT File Upload</h3>
          <div
            v-if="filesUploaded + fileUploadErrors == totalFilesToUpload"
            style="padding: 15px; margin: 15px; border: 5px solid green"
          >
            <h4>
              {{ $t("Upload abgeschlossen! Die Verarbeitung dauert ggf. noch einige Minuten.") }}
            </h4>
          </div>
          <div
            v-if="!(filesUploaded + fileUploadErrors == totalFilesToUpload)"
            style="padding: 15px; margin: 15px; border: 5px solid yellow"
          >
            <h4>{{ $t(`Upload läuft, bitte warten`) }}</h4>
          </div>
          <div v-if="totalFilesToUpload > 0">
            <h4 style="text-align: center">
              {{ filesUploaded }}/{{ totalFilesToUpload }}
              {{ $t(`erfolgreich`) }}
            </h4>
            <div
              v-if="filesUploaded + fileUploadErrors < totalFilesToUpload"
              class="spinner-border text-primary"
              role="status"
            ></div>
            <div style="text-align: center" class="centered top-spacer-25" v-if="fileUploadErrors > 0">
              {{ fileUploadErrors }} Dateien konnten nicht ausgewertet werden. Bitte nur vollständige .FIT Files
              hochladen.<br /><br />Hinweis: Zwift legt oft kleine unnötige FIT-Files ab, die hier als nicht-erfolgreich
              angezeigt werden. Das ist aber kein Problem! Die Workouts müssen nicht nochmal hochgeladen werden!
            </div>
            <br />

            <div
              v-if="fileUploadErrors + filesUploaded == totalFilesToUpload"
              style="cursor: pointer"
              class="top-spacer-25 button"
              @click="dragExit"
            >
              {{ $t(`Schließen`) }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <!-- Workout editieren -->
            <div
              v-if="showAiForm"
              class="overlay"
              @click="
                showAiForm = false;

                setScrollLock(false);
              "
            ></div>
            <div v-if="showAiForm" style="background: #fff" class="overlay-box">
              <div style="padding: 25px">
                <img
                  class="top-spacer-5"
                  style="box-shadow: none"
                  width="100px"
                  :src="require('@/assets/images/PL-Club-Logo-klein.png')"
                />
                <h3 style="margin-top: 10px">{{ $t(`KI-COACH`) }} // <span style="color: red">ALPHA</span></h3>

                <div class="col-12 top-spacer-10" v-if="warnPremiumExpiration" style="text-align: center; color: #fff">
                  <div
                    class="col-12"
                    style="background-size: 200% auto; padding: 20px; background: #000; border-radius: 10px"
                  >
                    <br />

                    {{
                      $t(
                        "Hinweis: Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, kannst du über die App im Profil ein Abo abschließen."
                      )
                    }}

                    <h4>
                      {{
                        $t(
                          `Der KI-Coach steht nur unseren zahlenden Club Mitgliedern zur Verfügung. Ohne Mitgliedschaft werden keine Workouts mehr in deinen Kalender eingefügt.`
                        )
                      }}
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="row">
                      <div class="col-6 top-spacer-25">
                        <button
                          style="width: 100%"
                          v-bind:class="mode == 'sports' ? 'closer' : ''"
                          @click="mode = 'sports'"
                        >
                          {{ $t(`Sportauswahl`) }}
                        </button>
                      </div>
                      <div class="col-6 top-spacer-25">
                        <button
                          style="width: 100%"
                          v-bind:class="mode == 'load' ? 'closer' : ''"
                          @click="mode = 'load'"
                        >
                          {{ $t(`Verteilung`) }}
                        </button>
                      </div>
                    </div>
                    <div class="col-md-12 top-spacer-25" v-if="mode == 'load'">
                      <div class="row top-spacer-10">
                        <div class="col-md-12" style="text-align: left">
                          Hier kannst du die Trainingsbelastung auf die Tage veteilen. Hast du am Sonntag zum Beispiel
                          mehr Zeit, kannst du für den Sonntag einen höheren Wert festlegen. Dadurch wird mehr Belastung
                          als normal auf den Sonntag geschoben. <br /><br />Die Verteilung der Punkte ist beliebig.
                          Setzt du zum Beispiel 10 Punkte auf Mo-Sa und 12 auf Sonntag, so wird die Belastung am Sonntag
                          ca. 20% höher ausfallen als an anderen Tagen - allerdings wird der Zielwert für die Einheit je
                          nach Vorbelastung, Sportart und weiteren Faktoren angepasst, entspricht also nicht unbedingt
                          20% mehr Belastung als an anderen Tagen.
                        </div>
                        <div class="col-6 top-spacer-25">
                          <h4>{{ $t(`Montag`) }}</h4>
                        </div>
                        <div class="col-6 top-spacer-25">
                          <input type="number" min="0" style="font-size: 14px; width: 100%" v-model="load_monday" />
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Dienstag</h4></div>
                        <div class="col-6">
                          <input type="number" min="0" style="font-size: 14px; width: 100%" v-model="load_tuesday" />
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Mittwoch</h4></div>
                        <div class="col-6">
                          <input type="number" min="0" style="font-size: 14px; width: 100%" v-model="load_wednesday" />
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Donnerstag</h4></div>
                        <div class="col-6">
                          <input type="number" min="0" style="font-size: 14px; width: 100%" v-model="load_thursday" />
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Freitag</h4></div>
                        <div class="col-6">
                          <input type="number" min="0" style="font-size: 14px; width: 100%" v-model="load_friday" />
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Samstag</h4></div>
                        <div class="col-6">
                          <input type="number" min="0" style="font-size: 14px; width: 100%" v-model="load_saturday" />
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Sonntag</h4></div>
                        <div class="col-6">
                          <input min="0" type="number" style="font-size: 14px; width: 100%" v-model="load_sunday" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 top-spacer-25">
                          Tage an denen kein Training stattfindet, werden automatisch herausgerechnet
                        </div>
                      </div>

                      <div
                        class="row top-spacer-25"
                        v-if="numberOfTrainingdays == 7"
                        style="border: 7px solid #eee; padding: 15px"
                      >
                        <div class="col-md-12">
                          <h4>Hinweis</h4>
                          Wir empfehlen mindestens einen trainingsfreien Tag pro Woche.
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12" style="text-align: left">
                          <hr />
                          <div class="top-spacer-15">
                            Der KI-Coach benötigt Trainingsdaten von 6 Wochen. Liegen diese noch nicht vor, kannst du
                            hier eine ungefähre tägliche Belastung definieren. Überschreitet dein Training den hier
                            definierten Load, wird der Base-Load ignoriert - er definiert also eine untere Belastung.
                            Erhöhe diesen Wert, wenn du mehr trainieren möchtest.
                            <hr />
                          </div>
                        </div>
                        <div class="col-6 top-spacer-25">
                          <h4>Base-Load</h4>
                        </div>

                        <div class="col-6 top-spacer-25">
                          <input type="number" min="1" style="font-size: 14px; width: 100%" v-model="baseLoad" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 top-spacer-25" v-if="mode == 'sports'">
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Montag</h4></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="monday">
                            <option value="">Kein Training</option>
                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10" v-if="monday != ''">
                        <div class="col-6"><i>2. Einheit</i></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="monday_2">
                            <option value="">Kein Training</option>
                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6">
                          <h4>{{ $t(`Dienstag`) }}</h4>
                        </div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="tuesday">
                            <option value="">Kein Training</option>

                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10" v-if="tuesday != ''">
                        <div class="col-6"><i>2. Einheit</i></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="tuesday_2">
                            <option value="">Kein Training</option>
                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Mittwoch</h4></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="wednesday">
                            <option value="">Kein Training</option>

                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10" v-if="wednesday != ''">
                        <div class="col-6"><i>2. Einheit</i></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="wednesday_2">
                            <option value="">Kein Training</option>
                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Donnerstag</h4></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="thursday">
                            >
                            <option value="">Kein Training</option>

                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10" v-if="thursday != ''">
                        <div class="col-6"><i>2. Einheit</i></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="thursday_2">
                            <option value="">Kein Training</option>
                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Freitag</h4></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="friday">
                            >
                            <option value="">Kein Training</option>

                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10" v-if="friday != ''">
                        <div class="col-6"><i>2. Einheit</i></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="friday_2">
                            <option value="">Kein Training</option>
                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Samstag</h4></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="saturday">
                            >
                            <option value="">Kein Training</option>

                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10" v-if="saturday != ''">
                        <div class="col-6"><i>2. Einheit</i></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="saturday_2">
                            <option value="">Kein Training</option>
                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10">
                        <div class="col-6"><h4>Sonntag</h4></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="sunday">
                            >
                            <option value="">Kein Training</option>

                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>
                      <div class="row top-spacer-10" v-if="sunday != ''">
                        <div class="col-6"><i>2. Einheit</i></div>
                        <div class="col-6">
                          <select style="font-size: 14px; width: 100%" v-model="sunday_2">
                            <option value="">Kein Training</option>
                            <option>Laufen</option>
                            <option>Radfahren</option>
                            <option>Schwimmen</option>
                            <option>Athletik</option>
                          </select>
                        </div>
                      </div>

                      <div
                        class="row top-spacer-25"
                        v-if="numberOfTrainingdays == 7"
                        style="border: 7px solid #eee; padding: 15px"
                      >
                        <div class="col-lg-12">
                          <h4>Hinweis</h4>
                          Wir empfehlen mindestens einen trainingsfreien Tag pro Woche.
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-12" style="text-align: left">
                          <hr />
                          <div class="top-spacer-15">
                            Der KI-Coach benötigt Trainingsdaten von 6 Wochen. Liegen diese noch nicht vor, kannst du
                            hier eine ungefähre tägliche Belastung definieren. Überschreitet dein Training den hier
                            definierten Load, wird der Base-Load ignoriert - er definiert also eine untere Belastung.
                            Erhöhe diesen Wert, wenn du mehr trainieren möchtest.
                            <hr />
                          </div>
                        </div>
                        <div class="col-6 top-spacer-25">
                          <h4>Base-Load</h4>
                        </div>

                        <div class="col-6 top-spacer-25">
                          <input type="number" style="font-size: 14px; width: 100%" v-model="baseLoad" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div
                      v-bind:class="
                        aiCoachActive == 'true' || aiCoachActive == true
                          ? 'border-gradient top-spacer-25'
                          : 'no-border-gradient top-spacer-25'
                      "
                    >
                      <div class="row">
                        <div class="col-6">
                          <h4><strong>Coach aktiv</strong></h4>
                        </div>
                        <div class="col-6">
                          <select v-model="aiCoachActive" style="font-size: 14px; width: 100%">
                            <option value="false">Nein</option>
                            <option value="true">Ja</option>
                          </select>
                        </div>
                      </div>
                      <div class="top-spacer-15">
                        Der KI-Coach ist noch brandneu und in der Entwicklungs- und Testphase. Wir freuen uns über dein
                        Feedback - was funktioniert gut, was noch nicht gut genug? Schick' uns eine Mail an
                        feedback@pushinglimits.club<br />
                        <br />
                        Du kannst den KI-Coach nutzen, um allgemein besser zu werden. Der KI-Coach versucht deine
                        Leistung kontinuierlich zu steigern - ohne dabei auf einen bestimmten Wettkampf abzuzielen.
                        <br /><br />Wenn du einen Wettkampf in deinem Kalender hinterlegt hast, wird das Training in den
                        Tagen vor dem Wettkampf und am Tag danach angepasst. <br />
                        <br />
                        Der KI-Coach legt dir ständig neue Workouts in den Kalender - wähle im Menü einfach deine
                        Trainingstage aus. Liegt an einem Tag bereits ein Workout im Kalender, wird kein weiteres
                        hinzugefügt. An jedem geplanten Tag wird immer ein Workout eingefügt.
                        <br />
                        <br />
                        Wenn du auf ein konkretes Ziel hin trainierst, empfehlen wir dir die Trainingspläne zu nutzen.
                        Trainiere nur, wenn du gesund und du nicht überlastet bist und bleibe immer innerhalb deiner
                        Grenzen.
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>

                  <div
                    class="spinner-border text-primary"
                    style="
                      width: 120px;
                      height: 120px;
                      left: 50%;
                      top: 50%;
                      margin-left: -60px;
                      margin-top: -60px;
                      position: fixed;
                    "
                    role="status"
                    v-if="aiCoachLoading"
                  ></div>

                  <div class="col-lg-12 top-spacer-25">
                    <button style="margin: 0 5px" class="secondary" type="submit" @click="setAiCoach()">
                      Speichern
                    </button>
                    <button style="margin: 0 5px" class="closer" type="submit" @click="showAiForm = false">
                      Schließen
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="showFeelingForm"
              class="overlay"
              @click="
                showFeelingForm = false;
                coachResults = undefined;
                coachWorkoutResults = [];
                setScrollLock(false);
              "
            ></div>

            <div v-if="showFeelingForm" style="background: #fff" class="overlay-box">
              <div style="padding: 50px">
                <div
                  v-if="coachLoading"
                  class="top-spacer-50 spinner-border text-primary"
                  role="status"
                  style="margin-bottom: 50px"
                ></div>
                <div v-if="!coachResults && !coachLoading">
                  <img style="box-shadow: none" width="80px" :src="require('@/assets/images/PL-Club-Logo-klein.png')" />
                  <h4>Trainingsempfehlungen</h4>
                  <h5 class="top-spacer-50">Wie fühlst du dich heute?</h5>
                  <h4>
                    <small>Beziehe dabei deinen Schlaf, Stress, Erschöpfung, muskuläre Ermüdung usw. mit ein</small>
                  </h4>
                  <div class="row top-spacer-25">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-12 top-spacer-10">
                      <button style="width: 100%" class="secondary" @click="sendFeeling(1)">Erschöpft / Krank</button>
                    </div>
                    <div class="col-lg-12 top-spacer-10">
                      <button style="width: 100%" class="secondary" @click="sendFeeling(2)">Müde</button>
                    </div>
                    <div class="col-lg-12 top-spacer-10">
                      <button style="width: 100%" class="secondary" @click="sendFeeling(3)">Okay</button>
                    </div>
                    <div class="col-lg-12 top-spacer-10">
                      <button style="width: 100%" class="secondary" @click="sendFeeling(4)">Gut</button>
                    </div>
                    <div class="col-lg-12 top-spacer-10">
                      <button style="width: 100%" class="secondary" @click="sendFeeling(5)">Sehr gut</button>
                    </div>
                  </div>
                  <hr class="top-spacer-25" />
                  <div class="top-spacer-25">
                    <div>
                      Basiered auf deinem Gefühl und deinen letzten Trainingseinheiten, deiner aktuellen Belastung,
                      deiner Trainingsintensitätsverteilung und weiteren Faktoren schlagen wir dir ein für heute
                      passendes Training vor. Wenn du ein konkretes Ziel verfolgst, empfehlen wir dir einen
                      Trainingsplan zu verwenden.
                    </div>
                    <div class="top-spacer-25"></div>
                    <hr />
                    <div class="top-spacer-25"></div>

                    <small
                      >Hinweis: Die Trainingsempfehlungen sind noch früh in der Entwicklung. Melde dich gerne bei uns,
                      wenn Probleme auftreten oder wenn du Feedback für uns hast.</small
                    >
                  </div>
                </div>

                <div v-if="coachResults && !coachLoading">
                  <div v-if="coachResults.feeling > 1 && !coachWorkoutResults.length">
                    <h5>Was möchtest du trainieren?</h5>
                    <div class="row">
                      <div class="col-lg-4 col-6 top-spacer-25 pointer" @click="getWorkouts('Radfahren')">
                        <img
                          src="https://assets.pushinglimits.club/d-cycle2.webp"
                          style="width: 100%; border-radius: 10px"
                        />
                        <h4 class="top-spacer-10">Radfahren</h4>
                      </div>
                      <div class="col-lg-4 col-6 top-spacer-25 pointer" @click="getWorkouts('Laufen')">
                        <img
                          src="https://assets.pushinglimits.club/d-run.webp"
                          style="width: 100%; border-radius: 10px"
                        />
                        <h4 class="top-spacer-10">Laufen</h4>
                      </div>
                      <div class="col-lg-4 col-6 top-spacer-25 pointer" @click="getWorkouts('Schwimmen')">
                        <img
                          src="https://assets.pushinglimits.club/d-swim2.webp"
                          style="width: 100%; border-radius: 10px"
                        />
                        <h4 class="top-spacer-10">Schwimmen</h4>
                      </div>
                    </div>
                  </div>

                  <div v-if="!coachWorkoutResults.length" class="top-spacer-50">
                    <h4>Hinweise zum Training</h4>
                    <div
                      class="top-spacer-25"
                      v-for="res of coachResults.warnings"
                      :key="res"
                      style="background: #b22222; color: #fff; border-radius: 10px; padding: 15px"
                    >
                      {{ res }}
                    </div>

                    <div
                      class="top-spacer-10"
                      v-for="res of coachResults.loadMessages"
                      :key="res"
                      style="background: green; color: #fff; border-radius: 10px; padding: 15px"
                    >
                      {{ res }}
                    </div>
                  </div>

                  <div v-if="coachResults.feeling > 1 && coachWorkoutResults.length">
                    <h5>Passende Vorschläge für dein heutiges Training</h5>
                    <br />

                    <div
                      v-for="(workout, index) of coachWorkoutResults"
                      :key="workout._id"
                      class="row top-spacer-25"
                      style="padding-bottom: 25px; border-bottom: 1px solid #ddd"
                    >
                      <div class="col-lg-6">
                        <div v-if="index == 0">
                          <i class="fa fa-star" style="color: gold" aria-hidden="true"></i>
                          Top-Empfehlung
                        </div>

                        <strong>{{ workout.name }}</strong>

                        <br />

                        <div
                          class="top-spacer-15"
                          style="padding: 0 60px; height: 50px"
                          v-if="workout.workoutFile && workout.workoutFile.length != 0"
                        >
                          <div class="col-lg-12" style="padding-left: 0px; padding-right: 0px">
                            <span v-for="value in workout.workoutFile" :key="value.id">
                              <svg
                                v-if="value.type == 'SteadyState'"
                                class="workoutBuilderBlock"
                                v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                                v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                              >
                                <rect
                                  v-bind:id="value.id"
                                  v-bind:width="100 + '%'"
                                  v-bind:height="value.Power / 4"
                                  v-bind:style="'fill:' + colorForPower(value.Power)"
                                />
                              </svg>

                              <svg
                                v-if="value.type == 'Ramp'"
                                class="workoutBuilderBlock"
                                v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                                v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                              >
                                <rect
                                  v-for="index in 20"
                                  :key="index"
                                  v-bind:id="value.id + '-' + index"
                                  v-bind:width="100 / 20 + '%'"
                                  v-bind:x="(100 / 20) * index - 5 + '%'"
                                  v-bind:height="getRampRectHeight(20, value.PowerLow / 4, value.PowerHigh / 4, index)"
                                  v-bind:style="
                                    'fill:' +
                                    colorForPower(getRampRectHeight(20, value.PowerLow, value.PowerHigh, index))
                                  "
                                />
                              </svg>

                              <svg
                                v-if="value.type == 'FreeRide'"
                                class="workoutBuilderBlock"
                                v-bind:width="value.Duration / 15"
                                v-bind:height="40"
                              >
                                <rect
                                  v-bind:id="value.id"
                                  v-bind:width="value.Duration / 15"
                                  v-bind:height="400"
                                  style="fill: rgba(94, 110, 255, 0.5)"
                                />
                              </svg>

                              <span v-if="value.type == 'IntervalsT'">
                                <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                                  <svg
                                    class="workoutBuilderBlock"
                                    v-bind:width="computeWidth(value.OnDuration, workout.workoutFile)"
                                    v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                                  >
                                    <rect
                                      v-bind:id="value.id"
                                      v-bind:width="'100%'"
                                      v-bind:height="value.OnPower / 4"
                                      v-bind:style="'fill:' + colorForPower(value.OnPower)"
                                    />
                                  </svg>
                                  <svg
                                    class="workoutBuilderBlock"
                                    v-bind:width="computeWidth(value.OffDuration, workout.workoutFile)"
                                    v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                                  >
                                    <rect
                                      v-bind:id="value.id"
                                      v-bind:width="'100%'"
                                      v-bind:height="value.OffPower / 4"
                                      v-bind:style="'fill:' + colorForPower(value.OffPower)"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <strong>Dauer: {{ $moment.utc(workout.durationShould * 1000).format("H:mm") }}h</strong><br />
                        LIT: {{ workout.estimate_time_lit }}%<br />
                        MIT: {{ workout.estimate_time_mit }}%<br />
                        HIT: {{ workout.estimate_time_hit }}%<br />
                      </div>

                      <div class="col-lg-3">
                        <button @click="addWorkoutToCalendar(workout)" class="top-spacer-25">Auswählen</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="top-spacer-50">
                  <button
                    @click="
                      showFeelingForm = false;
                      coachResults = undefined;
                      coachWorkoutResults = [];
                      setScrollLock(false);
                    "
                  >
                    Schließen
                  </button>
                </div>
              </div>
            </div>
            <div v-if="selectedCalendarWorkout != undefined && isMenuOpen" class="overlay" @click="hideOverlay()"></div>
            <div
              v-if="selectedCalendarWorkout != undefined && isMenuOpen"
              class="overlay-box"
              @keydown.esc="hideOverlay()"
            >
              <div class="overlay-close-button">
                <button class="closer round-close-button" type="submit" @click="hideOverlay()">
                  <i style="color: #fff" class="fa fa-times"></i>
                </button>
              </div>
              <div class="row">
                <div class="col-lg-12" style="margin: 0; border-bottom: 0">
                  <div class="clearfix" style="padding: 10px">
                    <h3 class="top-spacer-25">Einheit bearbeiten</h3>
                  </div>

                  <div style="padding: 30px; padding-top: 10px">
                    <new-workout
                      :key="thresholdKey"
                      v-bind:thresholds="findThresholdToDate(selectedCalendarWorkout.date)"
                      v-bind:workout="selectedCalendarWorkout"
                      @closed="hideOverlay"
                      @wasSaved="hideOverlay"
                      @workoutDeleted="handleWorkoutDeleted"
                      @workoutDeleteFailed="handleWorkoutDeleteFailed"
                    ></new-workout>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="newWorkoutDate != undefined && isMenuOpen"
              class="overlay"
              @click="hideNewWorkoutOverlay()"
            ></div>
            <div
              v-if="newWorkoutDate != undefined && isMenuOpen"
              class="overlay-box"
              @keydown.esc="hideNewWorkoutOverlay()"
            >
              <div class="row">
                <div class="col-lg-12" style="margin: 0; border-bottom: 0">
                  <div class="clearfix" style="padding: 10px">
                    <h3>Neue Einheit anlegen</h3>
                  </div>
                  <div style="float: right; z-index: 9999999999999999; position: sticky; top: 45px">
                    <div class="overlay-close-button">
                      <button class="closer round-close-button" type="submit" @click="hideOverlay()">
                        <i style="color: #fff" class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div style="padding: 30px; padding-top: 10px">
                    <new-workout
                      v-if="thresholds"
                      :key="thresholdKey"
                      v-bind:thresholds="thresholds"
                      v-bind:dayDate="newWorkoutDate"
                      @closed="hideNewWorkoutOverlay"
                      @wasSaved="hideNewWorkoutOverlay"
                    ></new-workout>
                  </div>
                </div>
              </div>
            </div>

            <!-- Workout anlegen mit WorkoutPicker -->
            <div
              v-if="newWorkoutDatePicker != undefined && isMenuOpen"
              class="overlay"
              @click="hideNewWorkoutPickerOverlay()"
            ></div>
            <div
              v-if="newWorkoutDatePicker != undefined && isMenuOpen"
              class="overlay-box-library white_wrapper"
              @keydown.esc="hideNewWorkoutPickerOverlay()"
            >
              <div class="row">
                <div class="col-lg-12" style="margin: 0; border-bottom: 0; background: #fff">
                  <div class="clearfix">
                    <h3>Workout aus Bibliothek wählen</h3>
                  </div>
                  <div style="text-align: right; z-index: 9999999999999999; position: sticky; top: 45px">
                    <div class="overlay-close-button">
                      <button class="closer round-close-button" type="submit" @click="hideNewWorkoutPickerOverlay()">
                        <i style="color: #fff" class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <workout-blueprints
                    v-if="thresholds"
                    :key="thresholdKey"
                    v-bind:thresholds="thresholds"
                    v-bind:dayDate="newWorkoutDatePicker"
                    @closed="hideNewWorkoutPickerOverlay"
                    @wasSaved="hideNewWorkoutPickerOverlay"
                  ></workout-blueprints>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overlayed" v-if="!loaded">
          <div class="spinner-border text-primary" style="width: 120px; height: 120px" role="status"></div>
        </div>
        <span>
          <!--
          <div class="white_wrapper_tighter" v-if="loaded && showCharts">
            <calendar-stress-chart-form-ramp
              style="height: 200px"
              :key="thresholdKey + 'chartG'"
              v-bind:fromDay="$moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))"
              v-bind:until="$moment(getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf('isoWeek'))"
            />
          </div>

          <div class="white_wrapper_tighter top-spacer-25" v-if="loaded && workouts && workouts.length > 0">
            <calendar-pss-chart
              style="height: 150px"
              v-bind:done="workouts"
              :key="thresholdKey + 'chartF'"
              v-bind:from="$moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))"
              v-bind:until="$moment(getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf('isoWeek'))"
            />
          </div>

          <div class="white_wrapper_tighter top-spacer-25" v-if="loaded && workouts && workouts.length > 0">
            <calendar-intensity-chart
              style="height: 150px"
              v-bind:done="workouts"
              :key="thresholdKey + 'chartH'"
              v-bind:from="$moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))"
              v-bind:until="$moment(getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf('isoWeek'))"
            />
          </div>

          <div class="white_wrapper_tighter top-spacer-25" v-if="loaded && workouts && workouts.length > 0">
            <calendar-duration-chart
              style="height: 150px"
              v-bind:done="workouts"
              :key="thresholdKey + 'chartP'"
              v-bind:from="$moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))"
              v-bind:until="$moment(getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf('isoWeek'))"
            />
          </div>-->

          <div class="col-lg-12 top-spacer-25 week light-shadow centered" style="margin-bottom: 25px">
            <button id="scroll-bottom" ref="loadPreviousButton" @click="loadCalendarEntriesTop" style="width: 250px">
              Vorherige Wochen laden
            </button>
          </div>
          <!--
        <div
          class="white_wrapper_tighter top-spacer-25"
          v-if="workouts && workouts.length > 0"
        >
          <calendar-distance-chart
            style="height: 150px"
            v-bind:done="workouts"
            :key="thresholdKey + 'chartF'"
            v-bind:from="
              $moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))
            "
            v-bind:until="
              $moment(
                getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf(
                  'isoWeek'
                )
              )
            "
          />
        </div>-->

          <div class="week top-spacer-25" v-for="week in loadedWeeks" :key="week">
            <div class="row">
              <div class="col-lg-12">
                <div v-if="plansByStartDate[getDayOfWeek(week, 0).startOf('week').format('YYYY-MM-DD')]">
                  <div
                    v-for="plan in plansByStartDate[getDayOfWeek(week, 0).startOf('week').format('YYYY-MM-DD')]"
                    :key="plan._id"
                    style="margin-bottom: 40px"
                  >
                    <h4>Start des Plans {{ plan.trainingsplan.title }}</h4>
                    <p style="text-align: left">
                      {{ plan.trainingsplan.description }}
                    </p>

                    <h4 class="top-spacer-25">Dein Training der nächsten Wochen im Überblick</h4>
                    <!-- Ladegeschwindigkeit leidet stark unter den Charts, ggf. prüfen 
                    <div class="white_wrapper_tighter top-spacer-25" v-if="workouts && workouts.length > 0">
                      <calendar-duration-chart
                        style="height: 150px"
                        v-bind:done="workouts"
                        :key="thresholdKey + 'chartF'"
                        v-bind:from="$moment().add(-21, 'd').startOf('day')"
                        v-bind:until="$moment().add(84, 'd').endOf('day')"
                      />
                    </div>
                    <div class="white_wrapper_tighter top-spacer-25" v-if="workouts && workouts.length > 0">
                      <calendar-intensity-chart
                        style="height: 150px"
                        v-bind:done="workouts"
                        :key="thresholdKey + 'chartF'"
                        v-bind:from="$moment().add(-21, 'd').startOf('day')"
                        v-bind:until="$moment().add(84, 'd').endOf('day')"
                      />
                    </div>

                    <div class="white_wrapper_tighter top-spacer-25" v-if="workouts && workouts.length > 0">
                      <calendar-pss-chart
                        style="height: 150px"
                        v-bind:done="workouts"
                        :key="thresholdKey + 'chartF'"
                        v-bind:from="$moment().add(-21, 'd').startOf('day')"
                        v-bind:until="$moment().add(84, 'd').endOf('day')"
                      />
                    </div>-->

                    <div class="white_wrapper_tighter top-spacer-25">
                      <calendar-stress-chart-form-ramp
                        style="height: 200px"
                        :key="thresholdKey + 'chartG'"
                        v-bind:fromDay="$moment().add(-14, 'd').startOf('day')"
                        v-bind:until="$moment().add(70, 'd').endOf('day')"
                      />
                      <div class="top-spacer-25">
                        Geschätzte Entwicklung deiner Belastung, wenn du nach Plan trainierst. Je nach bisherigem
                        Training und aktueller Trainingsphase ist es ganz normal, dass du auch mal im roten und grauen
                        Bereich trainierst. Achte aber darauf, nicht zu lange im roten Bereich zu bleiben - damit
                        könntest du dich überlasten.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-xs-7ths col-md-7ths cal-col"
                v-for="(n, index) in 7"
                :key="index"
                v-bind:id="'week-' + week + '-' + (index + 1)"
                style="vertical-align: top !important"
              >
                <div>
                  <div class="d-none d-md-block">
                    <div
                      class="heading"
                      :style="
                        week == 0 && index + 1 == (new Date().getDay() || 7)
                          ? 'color:#fff;background:#333;border-radius:10px'
                          : ''
                      "
                    >
                      {{ weekdays[index] }}
                    </div>

                    <span v-if="week == 0 && index + 1 == (new Date().getDay() || 7)">Heute</span>
                    <span v-if="!(week == 0 && index + 1 == (new Date().getDay() || 7))">{{
                      getDayOfWeek(week, index) | formatDate
                    }}</span>
                    <br />

                    <br />
                  </div>
                  <div class="row">
                    <div class="col-4 d-block d-md-none">
                      <div class="heading">{{ weekdays[index] }}</div>

                      {{ getDayOfWeek(week, index) | formatDate }}<br />

                      <strong v-if="week == 0 && index + 1 == (new Date().getDay() || 7)">Heute</strong><br />
                      <div style="padding: 10px" v-if="weather_prediction[[getDayOfWeekReverseFormat(week, index)]]">
                        <img
                          width="32px"
                          height="32px"
                          :src="`https://assets.pushinglimits.club/weather/${
                            weather_prediction[getDayOfWeekReverseFormat(week, index)].icon
                          }@2x.png`"
                        /><br />
                        <small>{{ weather_prediction[[getDayOfWeekReverseFormat(week, index)]].text }}</small
                        ><br />
                        <small v-if="weather_prediction[getDayOfWeekReverseFormat(week, index)].wind"
                          >༄
                          {{ Math.round(weather_prediction[getDayOfWeekReverseFormat(week, index)].wind) }}km/h</small
                        >
                      </div>
                    </div>

                    <div class="col-md-12 col-8" style="position: relative; height: 100%">
                      <div class="entry">
                        <div class="row">
                          <div v-bind:class="week == 0 && index + 1 == (new Date().getDay() || 7) ? 'col-4' : 'col-6'">
                            <a @click="showNewWorkoutOverlay(getDayOfWeek(week, index))" class="pointer">
                              <div>
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </div>
                            </a>
                          </div>
                          <div class="col-4" v-if="week == 0 && index + 1 == (new Date().getDay() || 7)">
                            <a
                              @click="
                                showFeelingForm = true;
                                setScrollLock(true);
                              "
                              class="pointer"
                            >
                              <div style="cursor: pointer">
                                <img
                                  class="top-spacer-5"
                                  style="box-shadow: none"
                                  width="25px"
                                  :src="require('@/assets/images/PL-Club-Logo-klein.png')"
                                /></div
                            ></a>
                          </div>

                          <div v-bind:class="week == 0 && index + 1 == (new Date().getDay() || 7) ? 'col-4' : 'col-6'">
                            <a @click="showNewWorkoutPickerOverlay(getDayOfWeek(week, index))" class="pointer">
                              <div>
                                <i class="fa fa-table" aria-hidden="true"></i>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="top-spacer-15"></div>
                      <div
                        class="workout-box d-none d-md-block"
                        style="cursor: auto; padding-top: 6px"
                        v-if="weather_prediction[[getDayOfWeekReverseFormat(week, index)]]"
                      >
                        <img
                          width="32px"
                          height="32px"
                          :src="`https://assets.pushinglimits.club/weather/${
                            weather_prediction[getDayOfWeekReverseFormat(week, index)].icon
                          }@2x.png`"
                        />
                        <small>{{ weather_prediction[[getDayOfWeekReverseFormat(week, index)]].text }}</small
                        ><br />
                        <small v-if="weather_prediction[getDayOfWeekReverseFormat(week, index)].wind"
                          >༄
                          {{ Math.round(weather_prediction[getDayOfWeekReverseFormat(week, index)].wind) }}km/h</small
                        >
                      </div>
                      <div
                        style="padding-top: 6px; font-size: 13px; cursor: default"
                        class="workout-box"
                        v-if="
                          health &&
                          health[getDayOfWeekReverseFormat(week, index)] &&
                          ((health[getDayOfWeekReverseFormat(week, index)].sleepData &&
                            Object.keys(health[getDayOfWeekReverseFormat(week, index)].sleepData).length) ||
                            (health[getDayOfWeekReverseFormat(week, index)].hrvData &&
                              Object.keys(health[getDayOfWeekReverseFormat(week, index)].hrvData).length) ||
                            (health[getDayOfWeekReverseFormat(week, index)].dailyData &&
                              Object.keys(health[getDayOfWeekReverseFormat(week, index)].dailyData).length))
                        "
                      >
                        <u>Garmin</u>
                        <div v-if="health[getDayOfWeekReverseFormat(week, index)].sleepData">
                          Schlaf:
                          {{
                            $moment
                              .utc(health[getDayOfWeekReverseFormat(week, index)].sleepData.durationInSeconds * 1000)
                              .format("HH:mm")
                          }}h
                        </div>

                        <div
                          v-if="
                            health[getDayOfWeekReverseFormat(week, index)].dailyData &&
                            health[getDayOfWeekReverseFormat(week, index)].dailyData.restingHeartRateInBeatsPerMinute >
                              0
                          "
                        >
                          Ruhe-HF:
                          {{
                            health[getDayOfWeekReverseFormat(week, index)].dailyData.restingHeartRateInBeatsPerMinute
                          }}
                        </div>

                        <div
                          v-if="
                            health[getDayOfWeekReverseFormat(week, index)].hrvData &&
                            health[getDayOfWeekReverseFormat(week, index)].hrvData.lastNightAvg > 0
                          "
                        >
                          HRV:
                          {{ health[getDayOfWeekReverseFormat(week, index)].hrvData.lastNightAvg }}
                        </div>
                      </div>
                      <div
                        class="workout-box"
                        v-if="
                          health &&
                          health[getDayOfWeekReverseFormat(week, index)] &&
                          health[getDayOfWeekReverseFormat(week, index)].whoopData &&
                          Object.keys(health[getDayOfWeekReverseFormat(week, index)].whoopData).length
                        "
                      >
                        <div v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData">
                          <u>Whoop</u><br />

                          <span v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopRecoveryScore">
                            Recovery:
                            {{ health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopRecoveryScore }}%<br
                          /></span>
                          <span
                            v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopTotalInBedTimeMilli"
                          >
                            <span
                              v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopTotalInBedTimeMilli"
                            >
                              Schlaf
                              {{
                                $moment
                                  .utc(
                                    health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopTotalInBedTimeMilli -
                                      health[getDayOfWeekReverseFormat(week, index)].whoopData
                                        .whoopTotalAwakeTimeMilli || 0
                                  )
                                  .format("HH:mm")
                              }}h
                              <span
                                v-if="
                                  health[getDayOfWeekReverseFormat(week, index)].whoopData
                                    .whoopSleepPerformancePercentage
                                "
                                >({{
                                  health[getDayOfWeekReverseFormat(week, index)].whoopData
                                    .whoopSleepPerformancePercentage
                                }}%)
                              </span>
                              <br />
                            </span>
                          </span>
                          <span v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopRestingHeartRate">
                            Ruhe-HF:
                            {{ health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopRestingHeartRate }}<br
                          /></span>
                          <span v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopHRVrmssdMilli">
                            HRV:
                            {{ health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopHRVrmssdMilli }}<br
                          /></span>
                          <!--<span v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopSpo2Percentage">
                            SPO2:
                            {{ health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopSpo2Percentage }}<br
                          /></span>-->
                          <span v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopSkinTempCelsius">
                            Hauttemp.:
                            {{
                              Math.round(
                                health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopSkinTempCelsius * 10
                              ) / 10
                            }}<br
                          /></span>

                          <!--<span
                            v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopTotalInBedTimeMilli"
                          >
                            Bettzeit:
                            {{
                              $moment
                                .utc(health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopTotalInBedTimeMilli)
                                .format("HH:mm")
                            }}h<br />
                          </span>-->
                          <!--<span
                            v-if="health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopTotalAwakeTimeMilli"
                          >
                            Wachzeit:
                            {{
                              $moment
                                .utc(health[getDayOfWeekReverseFormat(week, index)].whoopData.whoopTotalAwakeTimeMilli)
                                .format("HH:mm")
                            }}h
                          </span>-->
                          <!-- schlafzeit berechnen -->
                        </div>
                      </div>

                      <workout-entry
                        v-bind:workoutsOfDay="workoutsOfDate[week][index]"
                        @dataChanged="updateWorkoutData"
                        @wasClicked="showOverlay"
                        @workoutMoved="workoutMoved"
                        @newDate="setWorkoutsOfDate"
                        :key="thresholdKey"
                        v-bind:isMobile="isMobile"
                        v-bind:thresholds="findThresholdToDate(getDayOfWeek(week, index))"
                        v-if="
                          allThresholds &&
                          thresholds &&
                          findThresholdToDate(getDayOfWeek(week, index)) &&
                          workoutsOfDate &&
                          workoutsOfDate[week]
                        "
                        v-bind:wday="getDayOfWeek(week, index)"
                      >
                      </workout-entry>
                    </div>

                    <div class="col-12 d-block d-md-none top-spacer-25"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- TODO: Hide ?... -->
            <div class="col-lg-12" style="padding-top: 15px; border-top: 1px solid #f1f1f5">
              <!--<div class="row" >
            <div class="col-lg-12"><h4>Woche im Überblick</h4></div>
          </div>-->
              <div class="row">
                <div class="d-none d-lg-block col-lg-1 col-md-2 col-4">
                  <strong></strong><br />
                  <!--
              <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).swim+getWeeklyIsValues(week).bike+getWeeklyIsValues(week).run+getWeeklyIsValues(week).strength,  
              getWeeklyShouldValues(week).swim+getWeeklyShouldValues(week).bike+getWeeklyShouldValues(week).run+getWeeklyShouldValues(week).strength)">-->
                  Ist<br />
                  <small>Soll</small>
                  <br />
                  <a @click="editCalendar(week)" style="font-size: 10px">✎ Bearbeiten</a>
                  <!--/{{
              formatWorkoutTimes(
                getWeeklyShouldValues(week).swim +
                  getWeeklyShouldValues(week).bike +
                  getWeeklyShouldValues(week).run +
                  getWeeklyShouldValues(week).strength
              )
            }}-->
                  <!--</span>-->
                </div>
                <div class="col-lg-1 col-md-2 col-4">
                  <strong>Zeit</strong><br />
                  <!--
              <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).swim+getWeeklyIsValues(week).bike+getWeeklyIsValues(week).run+getWeeklyIsValues(week).strength,  
              getWeeklyShouldValues(week).swim+getWeeklyShouldValues(week).bike+getWeeklyShouldValues(week).run+getWeeklyShouldValues(week).strength)">-->
                  {{
                    formattedTimeSlash(
                      getWeeklyIsValues(week).swim +
                        getWeeklyIsValues(week).bike +
                        getWeeklyIsValues(week).run +
                        getWeeklyIsValues(week).strength
                    )
                  }}<br />
                  <small>{{
                    formattedTimeSlash(
                      getWeeklyShouldValues(week).swim +
                        getWeeklyShouldValues(week).bike +
                        getWeeklyShouldValues(week).run +
                        getWeeklyShouldValues(week).strength
                    )
                  }}</small>

                  <small
                    v-if="
                      getWeeklyShouldValues(week).swim +
                        getWeeklyShouldValues(week).bike +
                        getWeeklyShouldValues(week).run +
                        getWeeklyShouldValues(week).strength >
                      0
                    "
                  >
                    <br />{{
                      Math.round(
                        ((getWeeklyIsValues(week).swim +
                          getWeeklyIsValues(week).bike +
                          getWeeklyIsValues(week).run +
                          getWeeklyIsValues(week).strength) /
                          (getWeeklyShouldValues(week).swim +
                            getWeeklyShouldValues(week).bike +
                            getWeeklyShouldValues(week).run +
                            getWeeklyShouldValues(week).strength || 1)) *
                          100
                      )
                    }}%
                  </small>

                  <small
                    v-if="
                      getWeeklyShouldValues(week).swim +
                        getWeeklyShouldValues(week).bike +
                        getWeeklyShouldValues(week).run +
                        getWeeklyShouldValues(week).strength ==
                      0
                    "
                  >
                    <br />

                    100% </small
                  ><br />
                </div>

                <div class="col-lg-1 col-md-2 col-4">
                  <strong>Schwimmen</strong><br />
                  <!-- <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).swim, getWeeklyShouldValues(week).swim)">-->
                  {{ formattedTimeSlash(getWeeklyIsValues(week).swim) }}<br />
                  <small>{{ formattedTimeSlash(getWeeklyShouldValues(week).swim) }}</small>

                  <small v-if="getWeeklyShouldValues(week).swim > 0">
                    <br />
                    {{ Math.round((getWeeklyIsValues(week).swim / (getWeeklyShouldValues(week).swim || 1)) * 100) }}%
                  </small>
                  <small v-if="getWeeklyShouldValues(week).swim == 0">
                    <br />

                    100%
                  </small>
                  <!--/{{
              formatWorkoutTimes(getWeeklyShouldValues(week).swim)
            }}-->
                  <!-- </span>-->
                </div>
                <div class="col-lg-1 col-md-2 col-4">
                  <strong>Rad</strong><br />
                  <!-- <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).bike, getWeeklyShouldValues(week).bike)">-->
                  {{ formattedTimeSlash(getWeeklyIsValues(week).bike) }}<br />
                  <small>{{ formattedTimeSlash(getWeeklyShouldValues(week).bike) }}</small>
                  <small v-if="getWeeklyShouldValues(week).bike > 0">
                    <br />
                    {{ Math.round((getWeeklyIsValues(week).bike / (getWeeklyShouldValues(week).bike || 1)) * 100) }}%
                  </small>
                  <small v-if="getWeeklyShouldValues(week).bike == 0">
                    <br />

                    100%
                  </small>
                  <!--/{{
              formatWorkoutTimes(getWeeklyShouldValues(week).bike)
            }}-->
                  <!--</span>-->
                </div>
                <div class="col-12 d-block d-md-none"><br /></div>
                <div class="col-lg-1 col-md-2 col-4">
                  <strong>Laufen</strong><br />
                  <!--  <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).run, getWeeklyShouldValues(week).run)">-->
                  {{ formattedTimeSlash(getWeeklyIsValues(week).run) }}<br />
                  <small>{{ formattedTimeSlash(getWeeklyShouldValues(week).run) }}</small>
                  <small v-if="getWeeklyShouldValues(week).run > 0">
                    <br />
                    {{ Math.round((getWeeklyIsValues(week).run / (getWeeklyShouldValues(week).run || 1)) * 100) }}%
                  </small>
                  <small v-if="getWeeklyShouldValues(week).run == 0">
                    <br />

                    100%
                  </small>
                  <!--/{{
              formatWorkoutTimes(getWeeklyShouldValues(week).run)
            }}-->
                  <!--  </span>-->
                </div>
                <div class="col-lg-1 col-md-2 col-4">
                  <strong>Athletik</strong><br />
                  <!-- <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).strength, getWeeklyShouldValues(week).strength)">-->
                  {{ formattedTimeSlash(getWeeklyIsValues(week).strength) }}<br />
                  <small>{{ formattedTimeSlash(getWeeklyShouldValues(week).strength) }}</small>

                  <small v-if="getWeeklyShouldValues(week).strength > 0">
                    <br />
                    {{
                      Math.round(
                        (getWeeklyIsValues(week).strength / (getWeeklyShouldValues(week).strength || 1)) * 100
                      )
                    }}%
                  </small>
                  <small v-if="getWeeklyShouldValues(week).strength == 0">
                    <br />

                    100%
                  </small>
                </div>

                <div class="col-lg-1 col-md-2 col-4">
                  <strong>Aktivität</strong><br />
                  <!-- <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).activity, getWeeklyShouldValues(week).activity)">-->
                  {{ formattedTimeSlash(getWeeklyIsValues(week).activity) }}<br />
                  <small>{{ formattedTimeSlash(getWeeklyShouldValues(week).activity) }}</small>

                  <small v-if="getWeeklyShouldValues(week).activity > 0">
                    <br />
                    {{
                      Math.round(
                        (getWeeklyIsValues(week).activity / (getWeeklyShouldValues(week).activity || 1)) * 100
                      )
                    }}%
                  </small>
                  <small v-if="getWeeklyShouldValues(week).activity == 0">
                    <br />

                    100%
                  </small>
                </div>

                <div class="col-12 d-md-none d-block top-spacer-15"><br /></div>

                <div class="col-lg-3 col-md-3 col-12">
                  <strong>Intensitätsverteilung</strong>
                  <div class="progress" style="margin-top: 6px; font-size: 12px">
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      v-bind:style="
                        'width:' +
                        timePercentage(
                          getWeeklyIntensityValues(week).time_lit,
                          getWeeklyIntensityValues(week).time_hit +
                            getWeeklyIntensityValues(week).time_mit +
                            getWeeklyIntensityValues(week).time_lit
                        ) +
                        '%'
                      "
                      v-bind:aria-valuenow="
                        timePercentage(
                          getWeeklyIntensityValues(week).time_lit,
                          getWeeklyIntensityValues(week).time_hit +
                            getWeeklyIntensityValues(week).time_mit +
                            getWeeklyIntensityValues(week).time_lit
                        )
                      "
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span
                        v-if="
                          timePercentage(
                            getWeeklyIntensityValues(week).time_lit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          ) > 10
                        "
                      >
                        {{
                          timePercentage(
                            getWeeklyIntensityValues(week).time_lit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          )
                        }}%</span
                      >
                    </div>
                    <div
                      class="progress-bar bg-info"
                      role="progressbar"
                      v-bind:style="
                        'background-color: rgb(232, 126, 4)!important;' +
                        'width:' +
                        timePercentage(
                          getWeeklyIntensityValues(week).time_mit,
                          getWeeklyIntensityValues(week).time_hit +
                            getWeeklyIntensityValues(week).time_mit +
                            getWeeklyIntensityValues(week).time_lit
                        ) +
                        '%'
                      "
                      v-bind:aria-valuenow="
                        timePercentage(
                          getWeeklyIntensityValues(week).time_mit,
                          getWeeklyIntensityValues(week).time_hit +
                            getWeeklyIntensityValues(week).time_mit +
                            getWeeklyIntensityValues(week).time_lit
                        )
                      "
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span
                        v-if="
                          timePercentage(
                            getWeeklyIntensityValues(week).time_mit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          ) > 10
                        "
                      >
                        {{
                          timePercentage(
                            getWeeklyIntensityValues(week).time_mit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          )
                        }}%</span
                      >
                    </div>
                    <div
                      class="progress-bar bg-danger"
                      role="progressbar"
                      v-bind:style="
                        'width:' +
                        timePercentage(
                          getWeeklyIntensityValues(week).time_hit,
                          getWeeklyIntensityValues(week).time_hit +
                            getWeeklyIntensityValues(week).time_mit +
                            getWeeklyIntensityValues(week).time_lit
                        ) +
                        '%'
                      "
                      v-bind:aria-valuenow="
                        timePercentage(
                          getWeeklyIntensityValues(week).time_hit,
                          getWeeklyIntensityValues(week).time_hit +
                            getWeeklyIntensityValues(week).time_mit +
                            getWeeklyIntensityValues(week).time_lit
                        )
                      "
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span
                        v-if="
                          timePercentage(
                            getWeeklyIntensityValues(week).time_hit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          ) > 10
                        "
                      >
                        {{
                          timePercentage(
                            getWeeklyIntensityValues(week).time_hit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          )
                        }}%</span
                      >
                    </div>
                  </div>

                  <div style="margin-top: 10px">
                    Load: {{ computeWeeklyLoadValue(week) }}/{{ computeWeeklyLoadEstimateValue(week) || "-" }}
                  </div>
                </div>
                <div class="col-md-2 top-spacer-15">
                  <button
                    class="secondary"
                    style="padding: 5px; margin: 0; font-size: 13px"
                    @click="
                      $router.push({
                        name: 'AuswertungPage',
                        query: {
                          from: getDayOfWeek(week, 0).startOf('day').unix(),
                          until: getDayOfWeek(week, 6).endOf('day').unix(),
                        },
                        params: { page: 'workouts' },
                      })
                    "
                  >
                    Auswertung
                  </button>
                  <div class="d-block d-lg-none">
                    <a @click="editCalendar(week)" style="font-size: 10px">✎ Bearbeiten</a>
                  </div>
                  <div v-if="copyMode">
                    <button
                      class="closer"
                      style="padding: 5px; margin: 0; margin-top: 5px; font-size: 13px"
                      @click="copyWeek(copyMode.week, week)"
                    >
                      Woche einfügen
                    </button>
                  </div>
                </div>
              </div>

              <div v-if="showEditCalendar[week]" class="row">
                <div class="col-lg-12 top-spacer-25">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="d-block d-md-none"><hr /></div>

                      <strong>Geplante Einheiten der Woche löschen</strong><br />
                      <button class="top-spacer-10" @click="deleteWeek(week, index)">Woche löschen</button>
                      <div class="d-block d-md-none"><hr /></div>
                    </div>
                    <div class="col-lg-3">
                      <strong>Geplante Einheiten der Woche kopieren</strong><br />
                      <button class="top-spacer-10" @click="copyMode = { week, copy: true }">Woche kopieren</button>

                      <div v-if="copyMode" class="top-spacer-25">
                        Gehe nun zur Woche, in der du die geplanten Einheiten einfügen möchtest und drücke den Button
                        "Woche einfügen".
                      </div>
                      <div class="d-block d-md-none"><hr /></div>
                    </div>
                    <div class="col-lg-3">
                      <strong>Geplante Einheiten der Woche verschieben</strong><br />
                      Wochen:<br />
                      <select v-model="moveWeeks" style="width: 100%">
                        <option v-for="week in Array.from({ length: 13 }, (_, i) => i - 6)" :key="week" :value="week">
                          {{ week }}
                        </option>
                      </select>
                      <br />
                      <br />Tage<br />

                      <select v-model="moveDays" style="width: 100%">
                        <option v-for="day in Array.from({ length: 13 }, (_, i) => i - 6)" :key="day" :value="day">
                          {{ day }}
                        </option>
                      </select>
                      <br />
                      <button class="top-spacer-10" @click="moveWeek(week)">Verschieben</button>
                      <div class="d-block d-md-none"><hr /></div>
                    </div>
                    <div class="col-lg-3">
                      <strong>Alle Einheiten ab dieser Woche verschieben</strong><br />
                      Wochen:<br />
                      <select v-model="moveWeeks" style="width: 100%">
                        <option v-for="week in Array.from({ length: 13 }, (_, i) => i - 6)" :key="week" :value="week">
                          {{ week }}
                        </option>
                      </select>
                      <br />
                      <br />Tage<br />

                      <select v-model="moveDays" style="width: 100%">
                        <option v-for="day in Array.from({ length: 13 }, (_, i) => i - 6)" :key="day" :value="day">
                          {{ day }}
                        </option>
                      </select>
                      <br />
                      <button class="top-spacer-10" @click="moveAllWeeksStartingAt(week)">Verschieben</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="top-spacer-25"></div>

          <div class="col-lg-12 week light-shadow centered">
            <button id="scroll-bottom" ref="loadMoreButton" @click="loadCalendarEntriesBottom" style="width: 250px">
              Nächste Wochen laden
            </button>
          </div>
        </span>
      </div>

      <div class="top-spacer-50"></div>
    </div>
    <!--
    <div
      style="cursor: pointer"
      class="bottom-button-three"
      @click="toggleFood()"
      v-if="!$parent.$parent.showFoodInCalendar"
    >
      <img src="@/assets/images/food.png" style="width: 22px" />
    </div>

    <div
      style="cursor: pointer"
      class="bottom-button-three"
      @click="toggleFood()"
      v-if="$parent.$parent.showFoodInCalendar"
    >
      <img src="@/assets/images/food_filled.png" style="width: 22px" />
    </div>

    -->

    <div
      style="cursor: pointer"
      class="bottom-button-three"
      @click="showAiForm = !showAiForm"
      v-bind:style="aiCoachActive ? 'border:1px solid rgb(60,131,185)' : 'border:1px solid #fff'"
    >
      <img src="@/assets/images/ai.png" style="width: 25px; margin-top: -3px" />
    </div>
    <div style="cursor: pointer" class="bottom-button-two" @click="showStatisticsModal = true">
      <img src="@/assets/images/balken.png" style="width: 22px" />
    </div>

    <!-- Add this new modal for statistics -->
    <div v-if="showStatisticsModal" class="overlay" @click="showStatisticsModal = false"></div>
    <div v-if="showStatisticsModal" class="overlay-box statistics-modal">
      <div class="modal-header">
        <h3 style="text-align: left; width: 100%; font-size: 22px">Trainingsverlauf und -planung</h3>
        <div class="overlay-close-button">
          <button class="closer round-close-button" type="submit" @click="showStatisticsModal = false">
            <i style="color: #fff" class="fa fa-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-content">
        <div class="chart-container">
          <calendar-stress-chart-form-ramp
            style="height: 200px"
            :key="thresholdKey + 'chartG'"
            v-bind:fromDay="$moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))"
            v-bind:until="$moment(getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf('isoWeek'))"
          />
        </div>
        <div class="chart-container" v-if="workouts && workouts.length > 0">
          <calendar-pss-chart
            style="height: 150px"
            v-bind:done="workouts"
            :key="thresholdKey + 'chartF'"
            v-bind:from="$moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))"
            v-bind:until="$moment(getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf('isoWeek'))"
          />
        </div>
        <div class="chart-container" v-if="workouts && workouts.length > 0">
          <calendar-intensity-chart
            style="height: 150px"
            v-bind:done="workouts"
            :key="thresholdKey + 'chartH'"
            v-bind:from="$moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))"
            v-bind:until="$moment(getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf('isoWeek'))"
          />
        </div>
        <div class="chart-container" v-if="workouts && workouts.length > 0">
          <calendar-duration-chart
            style="height: 150px"
            v-bind:done="workouts"
            :key="thresholdKey + 'chartP'"
            v-bind:from="$moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))"
            v-bind:until="$moment(getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf('isoWeek'))"
          />
        </div>
      </div>
      <div class="modal-footer">
        <div class="overlay-close-button">
          <button class="closer round-close-button" type="submit" @click="showStatisticsModal = false">
            <i style="color: #fff" class="fa fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <div style="cursor: pointer; font-size: 13px" class="bottom-button" @click="showDatePicker = !showDatePicker">
      <div style="margin-top: 2px">Datum</div>
    </div>

    <div
      style="
        position: fixed;
        left: 95px;
        padding: 10px 0;
        width: 210px;
        overflow: hidden;
        bottom: 115px;
        border-radius: 20px;
        z-index: 999;
      "
      v-if="showDatePicker"
    >
      <div
        class="white_wrapper_tighter"
        style="
          background: #fff;
          padding: 0px 15px;
          max-width: 95%;
          min-width: 180px;
          margin: 4px;
          margin-bottom: 10px;
          box-shadow: rgba(5, 5, 5, 0.15) 0px 2px 6px 0px;
        "
      >
        <input
          type="date"
          style="font-size: 12px; color: #333; border: 0; padding: 3px 15px; width: 100%"
          v-model="selectDate"
          v-on:change="refresh()"
        />
      </div>
      <div
        class="white_wrapper_tighte pointer"
        type="date"
        @click="jumpToDate(new Date())"
        style="
          background: #fff;
          box-shadow: rgba(5, 5, 5, 0.15) 0px 2px 6px 0px;
          padding: 8px 25px;
          max-width: 95%;
          min-width: 180px;
          margin: 4px;
          border-radius: 10px;
        "
      >
        Heute
      </div>
    </div>

    <!--
    <div class="chart_fixed" style="padding-bottom: 0">
      <div style="max-width: 1600px; margin: auto">
        <div class="row">
          <div class="col-xl-2"></div>
          <div class="col-xl-10">
            <div
              class="white_wrapper_tighter"
              style="
                padding: 15px;
                padding-top: 20px;
                  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
                box-shadow: rgba(20, 20, 28, 0.13) 0px 6px 44px 0px !important;
              "
              v-if="workouts && workouts.length > 0"
            >
              <calendar-pss-chart
                style="height: 100px"
                v-bind:done="workouts"
                :key="thresholdKey + loadedWeeks[0] + 'chartF'"
                v-bind:from="
                  $moment(getDayOfWeek(loadedWeeks[0], 1).startOf('isoWeek'))
                "
                v-bind:until="
                  $moment(
                    getDayOfWeek(loadedWeeks[loadedWeeks.length - 1], 7).endOf(
                      'isoWeek'
                    )
                  )
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import axios from "axios";
import WorkoutEntry from "@/components/calendar/WorkoutEntry";
import NewWorkout from "@/components/workout/NewWorkout";
import WorkoutBlueprints from "@/components/workout/WorkoutBlueprints";
import calendar_utils from "@/js/calendar_utils";
import CalendarPssChart from "@/components/chart/CalendarPssChart.vue";
import CalendarIntensityChart from "@/components/chart/CalendarIntensityChart.vue";
import CalendarDurationChart from "@/components/chart/CalendarDurationChart.vue";
import CalendarStressChartFormRamp from "@/components/chart/CalendarStressChartFormRamp.vue";
import AppleHealthService from "@/services/AppleHealthService";

export default {
  metaInfo: {
    title: "Kalender",
  },
  name: "Calendar",
  components: {
    WorkoutEntry,
    NewWorkout,
    WorkoutBlueprints,
    CalendarPssChart,
    CalendarIntensityChart,
    CalendarDurationChart,
    CalendarStressChartFormRamp,
  },
  props: {
    msg: String,
  },
  data: function () {
    return {
      showEditCalendar: {},
      copyMode: false,
      moveWeeks: 0,
      moveDays: 0,
      showDatePicker: false,
      selectDate: undefined,
      mode: "sports",
      warnPremiumExpiration: false,
      user: undefined,
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: "",
      monday_2: "",
      tuesday_2: "",
      wednesday_2: "",
      thursday_2: "",
      friday_2: "",
      saturday_2: "",
      sunday_2: "",
      baseLoad: 15,
      load_monday: 10,
      load_tuesday: 10,
      load_wednesday: 10,
      load_thursday: 10,
      load_friday: 10,
      load_saturday: 10,
      load_sunday: 10,

      activeTrainingplans: [],
      plansByStartDate: {},
      showAiForm: false,
      aiCoachActive: false,
      aiCoachLoading: false,
      workoutsOfDate: {},
      // wird benoetigt fuer iPhone browser bei overlay, sonst springt der Browser immer wieder nach oben
      scrollPos: 0,
      list: new Array(250),

      health: undefined,

      showFeelingForm: false,
      isMobile: false,
      loaded: false,
      newWorkoutDate: undefined, // fuer WorkoutBuilder
      newWorkoutDatePicker: undefined, // Fuer Vorlagen-Picker
      selectedCalendarWorkout: undefined,
      workouts: [],
      weather_prediction: [],
      weekdays: [
        this.$t("Montag"),
        this.$t("Dienstag"),
        this.$t("Mittwoch"),
        this.$t("Donnerstag"),
        this.$t("Freitag"),
        this.$t("Samstag"),
        this.$t("Sonntag"),
      ],
      weekLoadingBlocked: false,
      loadedWeeks: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
      lastScroll: 0,
      thresholds: undefined,
      allThresholds: undefined,
      swimThreshold: 0,
      runThreshold: 0,
      ftp: 0,
      thresholdKey: 0,
      weeklyShould: [],
      // FIT UPLOAD
      fitFile: undefined,
      uploadFinished: false,
      uploadError: false,
      uploadFinishedLoader: true,
      showDropzone: false,
      totalFilesToUpload: 0,
      filesUploaded: 0,
      files: undefined,
      fileUploadErrors: 0,

      weeklyDistanceValues: [],
      weeklyDistanceShouldValues: {},
      weeklyIntensityValues: {},
      weeklyIsValues: {},
      weeklyShouldValues: {},

      showCharts: false,

      isMenuOpen: false,
      coachResults: undefined,
      coachLoading: false,
      coachWorkoutResults: [],
      isLoading: false,
      isLoadingTop: false,
      isLoadingBottom: false,
      showStatisticsModal: false,
    };
  },
  async created() {
    await this.updateThresholds();
  },
  destroyed() {
    //window.removeEventListener('scroll', this.handleScroll);
  },

  watch: {
    $route(newVal) {
      this.isMenuOpen = newVal.query?.isMenuOpen || false;

      if (!this.isMenuOpen) {
        this.newWorkoutDate = undefined;
        this.newWorkoutDatePicker = undefined;
        this.setScrollLock(false);
      } else {
        this.setScrollLock(true);
      }
    },
  },

  computed: {
    numberOfTrainingdays() {
      const training_days =
        (this.monday.length > 3 ? 1 : 0) +
        (this.tuesday.length > 3 ? 1 : 0) +
        (this.wednesday.length > 3 ? 1 : 0) +
        (this.thursday.length > 3 ? 1 : 0) +
        (this.friday.length > 3 ? 1 : 0) +
        (this.saturday.length > 3 ? 1 : 0) +
        (this.sunday.length > 3 ? 1 : 0);

      return training_days;
    },
  },

  methods: {
    editCalendar(week) {
      this.showEditCalendar[week] = !this.showEditCalendar[week];
      this.$forceUpdate();
    },

    handleWorkoutDeleted(workoutId) {
      // Remove the workout from the workouts array
      this.workouts = this.workouts.filter((workout) => workout._id !== workoutId);

      this.setWorkoutsOfDate();
    },

    handleWorkoutDeleteFailed() {
      this.setWorkoutsOfDate();
    },

    async deleteWeek(week) {
      const from = this.getDayOfWeek(week, 0);
      const until = this.getDayOfWeek(week, 6);
      this.loaded = false;

      try {
        await axios.delete(`${this.$host}/workout/delete/from/${from}/until/${until}`);
        await this.updateWorkoutData();
      } catch (error) {
        console.error("Fehler beim Löschen der Woche:", error);
      }
      this.loaded = true;
    },

    async moveWeek(week) {
      this.loaded = false;

      const moveDays = this.moveDays + this.moveWeeks * 7;
      const from = this.getDayOfWeekReverseFormat(week, 0);

      try {
        await axios.post(`${this.$host}/workout/move/from/${from}/days/${moveDays}`);
        await this.updateWorkoutData();
      } catch (error) {
        console.error("Fehler beim Kopieren der Woche:", error);
      }
      this.loaded = true;
    },

    async moveAllWeeksStartingAt(week) {
      this.loaded = false;

      const moveDays = this.moveDays + this.moveWeeks * 7;
      const from = this.getDayOfWeekReverseFormat(week, 0);

      try {
        await axios.post(`${this.$host}/workout/move/all/from/${from}/days/${moveDays}`);
        await this.updateWorkoutData();
      } catch (error) {
        console.error("Fehler beim Kopieren der Woche:", error);
      }
      this.loaded = true;
    },

    async copyWeek(week, to) {
      this.loaded = false;
      const from = this.getDayOfWeek(week, 0);
      const until = this.getDayOfWeek(week, 6);
      to = this.getDayOfWeek(to, 0);

      this.copyMode = true;
      try {
        await axios.post(`${this.$host}/workout/copy/from/${from}/until/${until}/to/${to}`);
        this.copyMode = false;
        await this.updateWorkoutData();
      } catch (error) {
        console.error("Fehler beim Kopieren der Woche:", error);
      }
      this.loaded = true;
    },

    async refresh() {
      console.log("refresh");
      await this.jumpToDate(this.selectDate);
      this.showDatePicker = false;
      await AppleHealthService.syncWorkoutsToApple();
    },

    async jumpToDate(date) {
      console.log("jump");

      this.loadedWeeks = [-3, -2, -1, 0, 1, 2, 3, 4];

      // weeks between now and given date
      let weeks = this.$moment(date).startOf("W").diff(this.$moment().startOf("W"), "week");

      // get day of week
      let dayOfWeek = this.$moment(date).day();
      if (dayOfWeek == 0) {
        dayOfWeek = 7;
      }

      console.log("weeks", weeks);

      // add weeks to all entries of loaded weeks
      this.loadedWeeks = this.loadedWeeks.map((week) => week + weeks);

      this.loaded = false;

      // update data
      await this.updateData();

      this.scrollToMiddle(weeks, dayOfWeek);
      this.showDatePicker = false;
    },

    organizePlansByStartDate() {
      this.activeTrainingplans.forEach((plan) => {
        const startDate = this.$moment(plan.start).startOf("week").format("YYYY-MM-DD");
        if (!this.plansByStartDate[startDate]) {
          this.plansByStartDate[startDate] = [];
        }
        this.plansByStartDate[startDate].push(plan);
      });

      // force update
      this.$forceUpdate();
    },

    getAiSettings: async function () {
      const _self = this;
      return axios.get(this.$host + "/autocoach/get_settings").then(async function (res) {
        if (res && res.data) {
          const result = res.data;
          _self.monday = result.sportSettings.Monday[0];
          _self.tuesday = result.sportSettings.Tuesday[0];
          _self.wednesday = result.sportSettings.Wednesday[0];
          _self.thursday = result.sportSettings.Thursday[0];
          _self.friday = result.sportSettings.Friday[0];
          _self.saturday = result.sportSettings.Saturday[0];
          _self.sunday = result.sportSettings.Sunday[0];

          _self.monday_2 = result.sportSettings.Monday[1] || "";
          _self.tuesday_2 = result.sportSettings.Tuesday[1] || "";
          _self.wednesday_2 = result.sportSettings.Wednesday[1] || "";
          _self.thursday_2 = result.sportSettings.Thursday[1] || "";
          _self.friday_2 = result.sportSettings.Friday[1] || "";
          _self.saturday_2 = result.sportSettings.Saturday[1] || "";
          _self.sunday_2 = result.sportSettings.Sunday[1] || "";

          _self.load_monday = result.loadSettings.Monday;
          _self.load_tuesday = result.loadSettings.Tuesday;
          _self.load_wednesday = result.loadSettings.Wednesday;
          _self.load_thursday = result.loadSettings.Thursday;
          _self.load_friday = result.loadSettings.Friday;
          _self.load_saturday = result.loadSettings.Saturday;
          _self.load_sunday = result.loadSettings.Sunday;

          _self.aiCoachActive = result.activated;
          _self.baseLoad = result.baseLoad;
        }
      });
    },
    setAiCoach() {
      const _self = this;
      this.aiCoachLoading = true;

      const req = {
        monday: this.monday,
        tuesday: this.tuesday,
        wednesday: this.wednesday,
        thursday: this.thursday,
        friday: this.friday,
        saturday: this.saturday,
        sunday: this.sunday,
        monday_2: this.monday_2,
        tuesday_2: this.tuesday_2,
        wednesday_2: this.wednesday_2,
        thursday_2: this.thursday_2,
        friday_2: this.friday_2,
        saturday_2: this.saturday_2,
        sunday_2: this.sunday_2,
        load_monday: this.load_monday,
        load_tuesday: this.load_tuesday,
        load_wednesday: this.load_wednesday,
        load_thursday: this.load_thursday,
        load_friday: this.load_friday,
        load_saturday: this.load_saturday,
        load_sunday: this.load_sunday,
        activated: this.aiCoachActive,
        baseLoad: this.baseLoad,
      };

      axios
        .post(this.$host + "/autocoach/set_settings", req)
        .then(async function () {
          await _self.getAiSettings();

          await _self.updateWorkoutData();

          _self.showAiForm = false;
          _self.aiCoachLoading = false;
          _self.setScrollLock(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addWorkoutToCalendar(workout) {
      let tempWorkoutDuration = workout.durationShould;
      if (workout.sport == "Laufen" || workout.sport == "Radfahren") {
        tempWorkoutDuration = this.totalWorkoutLength(workout.workoutFile);
      }

      // Distanz berechnen, falls duration und pace gegeben

      if (workout.sport == "Laufen") {
        workout.paceShould =
          calendar_utils.computeSpeedFromWorkoutFile(workout.workoutFile, this.thresholds.threshold_run) || 0;
      }

      if (workout.sport == "Laufen" && tempWorkoutDuration > 0 && workout.paceShould > 0) {
        workout.distanceShould = Math.round((tempWorkoutDuration / workout.paceShould) * 10) / 10;
      }

      let req = {
        name: workout.name,
        sport: workout.sport,
        description: workout.description,
        durationShould: tempWorkoutDuration,
        paceShould: workout.paceShould || 0,
        distanceShould: workout.distanceShould || 0,
        distance: workout.distance || 0,
        date: this.$moment(),
        workoutFile: workout.workoutFile,
        radraumWorkoutId: workout.radraumWorkoutId,
        durationIs: 0,
        paceIs: 0,
        youtubeEmbedLink: workout.youtubeEmbedLink,
        workout_blueprint: workout._id,
        isSuggestion: true,
        estimate_time_hit: workout.estimate_time_hit,
        estimate_time_lit: workout.estimate_time_lit,
        estimate_time_mit: workout.estimate_time_mit,
      };

      const _self = this;

      axios
        .post(this.$host + "/workout", req)
        .then(async function () {
          _self.showFeelingForm = false;
          _self.coachResults = undefined;
          _self.coachWorkoutResults = [];
          await _self.updateWorkoutData();

          _self.setScrollLock(false);
          _self.scrollToToday();
        })
        .catch(function (error) {
          console.log(error);
          _self.showFeelingForm = false;
          _self.coachResults = undefined;
          _self.coachWorkoutResults = [];
          _self.setScrollLock(false);
        });
    },
    getRampRectHeight(numberOfBlocks, startIntensity, endIntensity, index) {
      let res = startIntensity - ((startIntensity - endIntensity) / numberOfBlocks) * index;

      return res;
    },

    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },

    computeWidth(val, workoutFile) {
      const width = Math.ceil((val / this.totalWorkoutLength(workoutFile)) * 100) - 1;

      return width > 1 ? width + "%" : "0.75px";
    },

    workoutBuilderHeight(workoutFile) {
      let maxPower = Math.max.apply(
        Math,
        workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;

          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return Math.min(maxPower / 4, 35);
    },

    getWorkouts(sport) {
      const _self = this;

      _self.coachLoading = true;

      axios
        .post(_self.$host + "/autocoach/get_workouts", {
          sport,
          intensity: _self.coachResults.intensity,
        })
        .then(function (res) {
          _self.coachWorkoutResults = res.data;

          _self.coachWorkoutResults = _self.coachWorkoutResults.filter((wo) => {
            return wo.name.indexOf("FTP") == -1 && wo.name.indexOf("Test") == -1;
          });

          _self.coachWorkoutResults = _self.coachWorkoutResults.sort((a, b) => {
            return (
              Math.abs(_self.coachResults.optimalLoad - a.loadEstimate) -
              Math.abs(_self.coachResults.optimalLoad - b.loadEstimate)
            );
          });

          _self.coachLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          _self.coachLoading = false;
        });
    },

    sendFeeling(level) {
      const _self = this;

      _self.coachLoading = true;

      axios
        .post(_self.$host + "/autocoach/rate_feeling", { feeling: level })
        .then(function (res) {
          _self.coachResults = res.data;
          _self.coachLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          _self.coachLoading = false;
        });
    },
    toggleFood() {
      this.$parent.$parent.showFoodInCalendar = !this.$parent.$parent.showFoodInCalendar;
    },
    menuRoute() {
      this.setScrollLock(true);

      if (!this.$route.query.isMenuOpen) {
        this.isMenuOpen = true;
        this.$router.push({
          name: "Kalender",
          query: {
            isMenuOpen: true,
          },
        });
      } else {
        this.isMenuOpen = false;
        this.$router.push({
          name: "Kalender",
          query: {
            isMenuOpen: false,
          },
        });
      }
    },
    menuRouteClose() {
      this.$router.push({
        name: "Kalender",
      });
    },
    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return Math.round((100 / totalTime) * seconds);
      } else return 0;
    },
    formattedTimeSlash(seconds) {
      let days = 0;
      let ret = "";
      let tmpSeconds = seconds;
      while (tmpSeconds > 24 * 60 * 60) {
        days += 1;
        tmpSeconds -= 24 * 60 * 60;
      }
      if (days > 0) {
        ret += days + "/";
      }

      return ret + this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    setScrollLock(lockIt) {
      const el = document.body;

      if (lockIt) {
        el.classList.add("locked");
      } else {
        el.classList.remove("locked");
      }
    },

    colorIsShould(is, should) {
      return calendar_utils.colorForDuration(should, is);
    },
    workoutMoved(id, date) {
      this.workouts.filter((w) => w._id == id)[0].date = date;
      this.setWorkoutsOfDate();
    },
    async hideNewWorkoutOverlay() {
      this.menuRouteClose();

      await this.updateWorkoutData();
      this.newWorkoutDate = undefined;

      this.setScrollLock(false);
    },
    showNewWorkoutOverlay(date) {
      this.newWorkoutDate = date;
      this.menuRoute();
      this.setScrollLock(true);
    },
    async hideNewWorkoutPickerOverlay() {
      this.menuRouteClose();

      await this.updateWorkoutData();
      this.newWorkoutDatePicker = undefined;
      this.setScrollLock(false);
    },
    showNewWorkoutPickerOverlay(date) {
      this.newWorkoutDatePicker = date;
      this.menuRoute();
      this.setScrollLock(true);
    },

    showOverlay(id) {
      // TODO : If activityFile --> Direkt in Details
      // if(this.workouts.contains(workout).activityFile)
      this.selectedCalendarWorkout = this.workouts.find((element) => element._id == id);

      this.menuRoute();
      this.setScrollLock(true);
    },
    hideOverlayNoUpdate() {
      this.menuRouteClose();
      this.setScrollLock(false);
      this.selectedCalendarWorkout = undefined;
    },
    async hideOverlay() {
      this.menuRouteClose();
      this.setScrollLock(false);
      this.selectedCalendarWorkout = undefined;
      await this.updateWorkoutData();
    },
    /*handleScroll() {
        let html = document.documentElement;

        if(window.pageYOffset < 80) {
          this.loadCalendarEntriesTop();
        }

        else if (!html.offsetHeight- (parseInt((html.clientHeight) + parseInt(window.pageYOffset))) < 80) {
          this.loadCalendarEntriesBottom();
        }
    },*/

    formatWorkoutTimes(seconds) {
      return this.$moment().startOf("day").seconds(seconds).format("H:mm:ss");
    },
    async loadCalendarEntriesBottom() {
      if (this.isLoadingBottom) return; // Prevent multiple simultaneous calls
      this.isLoadingBottom = true;

      this.loaded = false;
      const _self = this;
      this.weekLoadingBlocked = true;

      this.loadedWeeks.shift();
      this.loadedWeeks.shift();
      this.loadedWeeks.shift();
      this.loadedWeeks.shift();

      this.loadedWeeks.push(this.loadedWeeks[this.loadedWeeks.length - 1] + 1);
      this.loadedWeeks.push(this.loadedWeeks[this.loadedWeeks.length - 1] + 1);
      this.loadedWeeks.push(this.loadedWeeks[this.loadedWeeks.length - 1] + 1);
      this.loadedWeeks.push(this.loadedWeeks[this.loadedWeeks.length - 1] + 1);

      await this.updateWorkoutData();

      const element = document.getElementById("week-" + _self.loadedWeeks[_self.loadedWeeks.length - 5] + "-" + 1);

      this.thresholdKey++;

      if (element) {
        var scrolledY = element.getBoundingClientRect().top + window.pageYOffset;

        let scroll = 134;
        if (window.innerWidth < 992) {
          scroll = 75;
        }

        if (scrolledY) {
          window.scroll(
            0,
            scrolledY - scroll - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
          );
        }
      }

      this.loaded = true;
      this.isLoadingBottom = false;
    },
    async loadCalendarEntriesTop() {
      if (this.isLoadingTop) return; // Prevent multiple simultaneous calls
      this.isLoadingTop = true;

      this.loaded = false;
      const _self = this;
      this.weekLoadingBlocked = true;
      this.loadedWeeks.pop();
      this.loadedWeeks.pop();
      this.loadedWeeks.pop();
      this.loadedWeeks.pop();

      this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
      this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
      this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
      this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);

      await this.updateWorkoutData();
      const element = document.getElementById("week-" + _self.loadedWeeks[4] + "-" + 1);
      this.thresholdKey++;
      if (element) {
        var scrolledY = element.getBoundingClientRect().top + window.pageYOffset;

        let scroll = 134;
        if (window.innerWidth < 992) {
          scroll = 75;
        }

        if (scrolledY) {
          window.scroll(
            0,
            scrolledY - scroll - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
          );
        }
      }

      this.loaded = true;
      this.isLoadingTop = false;
    },

    getDayOfWeek: function (week, day) {
      return this.$moment().startOf("isoWeek").add(week, "w").add(day, "d");
    },

    getDayOfWeekReverseFormat: function (week, day) {
      return this.$moment().startOf("isoWeek").add(week, "w").add(day, "d").format("YYYY-MM-DD");
    },

    getWeeklyIntensityValues(week) {
      return (
        this.weeklyIntensityValues[week] || {
          swim: 0,
          bike: 0,
          run: 0,
          strength: 0,
          activity: 0,
        }
      );
    },

    computeWeeklyIntensityValues(week) {
      const startDate = this.$moment().add(week, "w").startOf("isoWeek");
      const endDate = this.$moment().add(week, "w").endOf("isoWeek");

      const workouts = this.workouts.filter(
        (w) =>
          this.$moment(w.date).isSameOrAfter(startDate) &&
          this.$moment(w.date).isSameOrBefore(endDate) &&
          w.durationIs > 0
      );
      let weeklyIntensity = { time_hit: 0, time_mit: 0, time_lit: 0 };
      workouts.forEach(function (w) {
        weeklyIntensity.time_hit += w.time_hit * (w.durationIs || w.durationShould);
        weeklyIntensity.time_mit += w.time_mit * (w.durationIs || w.durationShould);
        weeklyIntensity.time_lit += w.time_lit * (w.durationIs || w.durationShould);
      });
      return weeklyIntensity;
    },

    getWeeklyIsValues(week) {
      return (
        this.weeklyIsValues[week] || {
          swim: 0,
          bike: 0,
          run: 0,
          strength: 0,
          activity: 0,
        }
      );
    },

    computeWeeklyIsValues(week) {
      const startDate = this.$moment().add(week, "w").startOf("isoWeek");
      const endDate = this.$moment().add(week, "w").endOf("isoWeek");

      const workouts = this.workouts.filter(
        (w) => this.$moment(w.date).isSameOrAfter(startDate) && this.$moment(w.date).isSameOrBefore(endDate)
      );
      let weeklyDuration = {
        swim: 0,
        run: 0,
        bike: 0,
        strength: 0,
        activity: 0,
      };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDuration.swim += w.durationIs || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDuration.bike += w.durationIs || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDuration.run += w.durationIs || 0;
        }
        if (w.sport == "Kraft") {
          weeklyDuration.strength += w.durationIs || 0;
        }
        if (w.sport == "Aktivität") {
          weeklyDuration.activity += w.durationIs || 0;
        }
      });

      return weeklyDuration;
    },

    computeWeeklyLoadValue(week) {
      const startDate = this.$moment().add(week, "w").startOf("isoWeek");
      const endDate = this.$moment().add(week, "w").endOf("isoWeek");

      const workouts = this.workouts.filter(
        (w) =>
          w.sport != " Aktivität" &&
          this.$moment(w.date).isSameOrAfter(startDate) &&
          this.$moment(w.date).isSameOrBefore(endDate) &&
          w.sport != "Aktivität"
      );
      let weeklyLoad = 0;
      workouts.forEach(function (w) {
        weeklyLoad += w.pss;
      });

      return weeklyLoad;
    },

    computeWeeklyLoadEstimateValue(week) {
      const startDate = this.$moment().add(week, "w").startOf("isoWeek");
      const endDate = this.$moment().add(week, "w").endOf("isoWeek");

      const workouts = this.workouts.filter(
        (w) => this.$moment(w.date).isSameOrAfter(startDate) && this.$moment(w.date).isSameOrBefore(endDate)
      );
      let weeklyLoad = 0;
      workouts.forEach(function (w) {
        weeklyLoad += w.loadEstimate;
      });

      return weeklyLoad;
    },

    getWeeklyShouldValues(week) {
      return (
        this.weeklyShouldValues[week] || {
          swim: 0,
          bike: 0,
          run: 0,
          strength: 0,
          activity: 0,
        }
      );
    },

    computeWeeklyShouldValues(week) {
      const startDate = this.$moment().add(week, "w").startOf("isoWeek");
      const endDate = this.$moment().add(week, "w").endOf("isoWeek");
      const workouts = this.workouts.filter(
        (w) => this.$moment(w.date).isSameOrAfter(startDate) && this.$moment(w.date).isSameOrBefore(endDate)
      );
      let weeklyDuration = {
        swim: 0,
        run: 0,
        bike: 0,
        strength: 0,
        activity: 0,
      };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDuration.swim += w.durationShould || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDuration.bike += w.durationShould || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDuration.run += w.durationShould || 0;
        }
        if (w.sport == "Kraft") {
          weeklyDuration.strength += w.durationShould || 0;
        }
        if (w.sport == "Aktivität") {
          weeklyDuration.activity += w.durationShould || 0;
        }
      });
      return weeklyDuration;
    },

    getWeeklyDistanceValues(week) {
      return (
        this.weeklyDistanceValues[week] || {
          swim: 0,
          bike: 0,
          run: 0,
          strength: 0,
          activity: 0,
        }
      );
    },

    getWeeklyDistanceShouldValues(week) {
      return (
        this.weeklyDistanceShouldValues[week] || {
          swim: 0,
          bike: 0,
          run: 0,
          strength: 0,
          activity: 0,
        }
      );
    },

    // todo: diese funktion wird sehr oft aufgerufen -
    // cachen?!! nach uodate data alle berechnen und dann direkt aufrufen!
    computeWeeklyDistanceValues(week) {
      const startDate = this.$moment().add(week, "w").startOf("isoWeek");
      const endDate = this.$moment().add(week, "w").endOf("isoWeek");

      const workouts = this.workouts.filter(
        (w) =>
          this.$moment(w.date).isSameOrAfter(startDate) &&
          this.$moment(w.date).isSameOrBefore(endDate) &&
          w.sport != "Aktivität"
      );
      let weeklyDistance = {
        swim: 0,
        run: 0,
        bike: 0,
        strength: 0,
        activity: 0,
      };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDistance.swim += w.distance || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDistance.bike += w.distance || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDistance.run += w.distance || 0;
        }
        if (w.sport == "Aktivität") {
          weeklyDistance.activity += w.distance || 0;
        }
      });

      weeklyDistance.run = Math.round(weeklyDistance.run * 10) / 10;
      weeklyDistance.bike = Math.round(weeklyDistance.bike * 10) / 10;
      weeklyDistance.swim = Math.round(weeklyDistance.swim * 10) / 10;
      weeklyDistance.activity = Math.round(weeklyDistance.activity * 10) / 10;

      return weeklyDistance;
    },

    computeWeeklyDistanceShouldValues(week) {
      const startDate = this.$moment().add(week, "w").startOf("isoWeek");
      const endDate = this.$moment().add(week, "w").endOf("isoWeek");

      const workouts = this.workouts.filter(
        (w) => this.$moment(w.date).isSameOrAfter(startDate) && this.$moment(w.date).isSameOrBefore(endDate)
      );
      let weeklyDistance = {
        swim: 0,
        run: 0,
        bike: 0,
        strength: 0,
        activity: 0,
      };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDistance.swim += w.distanceShould || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDistance.bike += w.distanceShould || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDistance.run += w.distanceShould || 0;
        }
        if (w.sport == "Aktivität") {
          weeklyDistance.activity += w.distanceShould || 0;
        }
      });

      weeklyDistance.run = Math.round(weeklyDistance.run * 10) / 10;
      weeklyDistance.bike = Math.round(weeklyDistance.bike * 10) / 10;
      weeklyDistance.swim = Math.round(weeklyDistance.swim * 10) / 10;
      weeklyDistance.activity = Math.round(weeklyDistance.activity * 10) / 10;

      return weeklyDistance;
    },

    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },

    setWorkoutsOfDate: function () {
      for (let week of this.loadedWeeks) {
        if (!this.workoutsOfDate[week]) {
          this.workoutsOfDate[week] = {};
        }
        for (let i = 0; i <= 6; i++) {
          const date = this.$moment().startOf("isoWeek").add(week, "w").add(i, "d");

          const workouts = this.workouts.filter((workout) => {
            return date.isSame(this.$moment(workout.date), "day");
          });

          const sortedWorkouts = workouts.slice().sort(function (a, b) {
            if (!a.orderOfDay) {
              a.orderOfDay = 0;
            }
            if (!b.orderOfDay) {
              b.orderOfDay = 0;
            }
            return a.orderOfDay - b.orderOfDay;
          });

          this.workoutsOfDate[week][i] = { workouts: sortedWorkouts, date: date };
        }
      }
    },

    getWorkoutsOfDate: function (week, day) {
      return this.workoutsOfDate[week][day];
    },

    updateWeather: async function () {
      const _self = this;
      return axios.get(_self.$host + "/weather/user_location").then(function (res) {
        if (res && res.data && res.data.weather_days) {
          _self.weather_prediction = res.data.weather_days;
        }
      });
    },

    updateWorkoutData: async function () {
      const _self = this;

      const startOfWeek = this.getDayOfWeek(this.loadedWeeks[0], 1).startOf("isoWeek").unix() * 1000;
      const endOfWeek =
        this.getDayOfWeek(this.loadedWeeks[this.loadedWeeks.length - 1] + 12, 7)
          .endOf("isoWeek")
          .unix() * 1000;

      const workoutUrl = `${this.$host}/workout/from/${startOfWeek}/until/${endOfWeek}`;

      const [workoutRes] = await Promise.all([axios.get(workoutUrl)]);

      _self.workouts = workoutRes.data;

      _self.setWorkoutsOfDate();

      for (const week of _self.loadedWeeks) {
        _self.weeklyDistanceValues[week] = _self.computeWeeklyDistanceValues(week);

        _self.weeklyDistanceShouldValues[week] = _self.computeWeeklyDistanceShouldValues(week);

        _self.weeklyIntensityValues[week] = _self.computeWeeklyIntensityValues(week);

        _self.weeklyIsValues[week] = _self.computeWeeklyIsValues(week);
        _self.weeklyShouldValues[week] = _self.computeWeeklyShouldValues(week);
      }

      _self.loaded = true;

      return Promise.resolve();
    },

    updateData: async function () {
      const _self = this;

      const startOfWeek = this.getDayOfWeek(this.loadedWeeks[0], 1).startOf("isoWeek").unix() * 1000;
      const endOfWeek =
        this.getDayOfWeek(this.loadedWeeks[this.loadedWeeks.length - 1] + 12, 7)
          .endOf("isoWeek")
          .unix() * 1000;

      const healthUrl = `${this.$host}/health/from/${startOfWeek}/until/${endOfWeek}`;
      const workoutUrl = `${this.$host}/workout/from/${startOfWeek}/until/${endOfWeek}`;

      const [healthRes, workoutRes] = await Promise.all([axios.get(healthUrl), axios.get(workoutUrl)]);

      _self.health = healthRes.data;
      _self.workouts = workoutRes.data;

      _self.setWorkoutsOfDate();

      for (const week of _self.loadedWeeks) {
        _self.weeklyDistanceValues[week] = _self.computeWeeklyDistanceValues(week);

        _self.weeklyDistanceShouldValues[week] = _self.computeWeeklyDistanceShouldValues(week);

        _self.weeklyIntensityValues[week] = _self.computeWeeklyIntensityValues(week);

        _self.weeklyIsValues[week] = _self.computeWeeklyIsValues(week);
        _self.weeklyShouldValues[week] = _self.computeWeeklyShouldValues(week);
      }

      _self.loaded = true;

      return Promise.resolve();
    },

    async updateThresholds() {
      // TODO: Get only last Threshold
      this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;
      this.thresholdKey++;

      this.allThresholds = await (await axios.get(this.$host + "/user/thresholds")).data;
    },

    findThresholdToDate(targetDate) {
      const parsedTargetDate = new Date(targetDate);

      let closestMatch = null;
      let closestTimeDiff = Infinity;

      this.allThresholds.forEach((item) => {
        const createdAt = new Date(item.createdAt);
        const timeDiff = parsedTargetDate - createdAt;

        if (timeDiff < closestTimeDiff) {
          closestMatch = item;
          closestTimeDiff = timeDiff;
        }
      });

      return closestMatch;
    },

    dragEnter(e) {
      if (e && e.dataTransfer && e.dataTransfer.files.length > 0) {
        this.showDropzone = true;
      }
    },

    async scrollToTodayNoUpdate() {
      const element = document.getElementById("week-0-" + (new Date().getDay() || 7));
      if (element) {
        var scrolledY = element.getBoundingClientRect().top + window.pageYOffset;

        let scroll = 135;
        if (window.innerWidth < 992) {
          scroll = 75;
        }

        if (scrolledY) {
          window.scroll(
            0,
            scrolledY - scroll - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
          );
        }
      }
    },

    async scrollToToday() {
      this.loadedWeeks = [-3, -2, -1, 0, 1, 2, 3];
      await this.updateData();
      const element = document.getElementById("week-0-" + (new Date().getDay() || 7));
      if (element) {
        var scrolledY = element.getBoundingClientRect().top + window.pageYOffset;

        let scroll = 135;
        if (window.innerWidth < 992) {
          scroll = 75;
        }

        if (scrolledY) {
          window.scroll(
            0,
            scrolledY - scroll - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
          );
        }
      }
    },

    scrollToMiddle(week, day) {
      const element = document.getElementById("week-" + week + "-" + (day || 7));
      if (element) {
        var scrolledY = element.getBoundingClientRect().top + window.pageYOffset;

        let scroll = 135;
        if (window.innerWidth < 992) {
          scroll = 75;
        }

        setTimeout(() => {
          if (scrolledY) {
            window.scroll(
              0,
              scrolledY - scroll - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
            );
          }
        }, 150);
      }
    },

    async dragExit() {
      this.showDropzone = false;
      this.filesUploaded = 0;
      this.fileUploadErrors = 0;
      this.totalFilesToUpload = 0;
      await this.updateWorkoutData();
    },
    async addFile(e) {
      if (e && e.dataTransfer && e.dataTransfer.files.length > 0) {
        let droppedFiles = e.dataTransfer.files;
        this.showDropzone = true;

        if (this.totalFilesToUpload - this.filesUploaded - this.fileUploadErrors == 0) {
          if (!droppedFiles) return;

          this.filesUploaded = 0;
          this.fileUploadErrors = 0;

          this.totalFilesToUpload = droppedFiles.length;
          const _self = this;

          for (const f of droppedFiles) {
            await new Promise((resolve) => {
              this.uploadFinishedLoader = false;
              const formData = new FormData();
              this.uploadFinished = false;
              this.uploadError = false;

              formData.append("files[]", f);

              axios
                .post(_self.$host + "/fit/upload", formData)
                .then(function () {
                  _self.uploadFinished = true;
                  _self.filesUploaded += 1;
                })
                .catch(function () {
                  _self.uploadError = true;
                  _self.fileUploadErrors += 1;
                })
                .finally(function () {
                  _self.uploadFinishedLoader = true;
                  resolve();

                  if (_self.totalFilesToUpload - _self.filesUploaded == 0) {
                    //_self.dragExit();
                  }
                });
            });
          }

          await _self.updateWorkoutData();
        }
      }
    },
  },

  mounted: async function () {
    this.loaded = false;
    const _self = this;

    try {
      const [_activeTrainingplans, _user] = await Promise.all([
        axios.get(this.$host + "/trainingsplan/list/user"),
        axios.get(this.$host + "/user/me"),
        this.updateWeather(),
        this.updateData(),
        this.getAiSettings(),
      ]);

      this.activeTrainingplans = _activeTrainingplans.data;
      this.user = _user.data;

      try {
        this.$moment.tz.setDefault(this.user.timezone || "Europe/Berlin");
        this.selectDate = this.$moment().format("yyyy-MM-DD");
        this.organizePlansByStartDate();
      } catch (err) {
        console.log("Fehler beim setzen der TZ", this.user.timezone);
      }

      if (
        this.$moment(this.user.premiumAccountUntil).isBefore(this.$moment()) &&
        this.$moment(this.user.freeAccountUntil).isBefore(this.$moment())
      ) {
        this.warnPremiumExpiration = true;
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Daten " + error, error);
    }

    this.isMobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 760;

    window.addEventListener("resize", function () {
      // check width
      _self.isMobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 760;
    });

    _self.showCharts = true;
    this.scrollToTodayNoUpdate();

    setTimeout(() => {
      this.scrollToTodayNoUpdate();
    }, 200); // Adjust this delay if needed
    setTimeout(() => {
      this.scrollToTodayNoUpdate();
    }, 400);
    // Use a combination of $nextTick and setTimeout
    this.$nextTick(() => {
      setTimeout(() => {
        this.scrollToTodayNoUpdate();
      }, 10); // Adjust this delay if needed
    });

    // Add Intersection Observer for the "Vorherige Wochen laden" button
    const observerTop = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.loadCalendarEntriesTop();
          }
        });
      },
      { threshold: 0.1 }
    );
    observerTop.observe(this.$refs.loadPreviousButton);

    // Add Intersection Observer for the "Nächste Wochen laden" button
    const observerBottom = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.loadCalendarEntriesBottom(false);
          }
        });
      },
      { threshold: 0.1 }
    );
    observerBottom.observe(this.$refs.loadMoreButton);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.loading {
  background: green;
}

.heading {
  font-weight: bold;
  font-size: 16px;
}

i {
  font-size: 16px;
  padding: 7px;
  color: #aaa;
}

.entry {
  background: #f1f1f5;
  padding: 5px;
  border-radius: 10px;

  bottom: 0px;
  z-index: 100;
}

.chart_fixed {
  position: fixed;
  bottom: 0;
  padding: 15px;
  left: 0;
  right: 0;

  z-index: 20;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
  overflow: hidden;
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  max-width: 750px;
  min-width: 50%;

  left: 50%;
  margin-left: -375px;
  overflow-x: hidden;
  top: calc(env(safe-area-inset-top) + 3%);
  bottom: 3%;

  z-index: 99999;
  border-radius: 10px;
}

#dropzone {
  padding: 30px;
  padding-top: 5px;
  text-align: center;
  position: fixed;
  margin: auto;
  left: 10%;
  border-radius: 5px;
  right: 10%;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  border: 3px solid #000;
  z-index: 999999999999;
  overflow-y: scroll;
}

@media (min-width: 768px) {
  .cal-col:not(:last-child) {
    border-right: 2px dotted #ddd;
  }
}

.overlay-box-library {
  position: fixed;
  left: 2%;
  right: 2%;
  top: calc(env(safe-area-inset-top) + 2%);
  bottom: 2%;
  margin: auto;

  padding: 0;
  overflow-x: hidden;
  z-index: 99999999;
  max-width: 1400px;
  border-radius: 5px;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  filter: brightness(70%);
}

@media (max-width: 800px) {
  .overlay-box {
    position: fixed;

    left: 20px;
    right: 20px;
    top: calc(env(safe-area-inset-top) + 5px);
    bottom: 20px;
    margin-left: 0;
  }
  .overlay-box-library {
    position: fixed;
    left: 20px;
    right: 20px;
    top: calc(env(safe-area-inset-top) + 5px);
    bottom: 20px;
    background: #fff;
    padding: 0;
    overflow-x: hidden;
    z-index: 99999999;
  }
}

.statistics-modal {
  max-width: 90%;
  width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.close-button {
  font-size: 24px;
  font-weight: bold;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #555;
}

.modal-content {
  padding: 20px;
  border: 0;
}

.chart-container {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .statistics-modal {
    width: 95%;
    max-height: 95vh;
  }
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.close-modal-button {
  padding: 10px 20px;
  background-color: #f1f1f1;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close-modal-button:hover {
  background-color: #ccc;
}

.overlay-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 9999;
}

.round-close-button {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  color: #fff;
  padding: 0;
  border-radius: 50%;
  transform: translate3d(0, 0, 0);
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-close-button:hover {
  color: #ccc;
}
</style>
